import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  links: {},
  meta: {},
};
const getters = {
  GET_TRIPS: state => state,
};
const mutations = {
  UPDATE_TRIPS: (state, payload) => {
    let newArray = [];
    state.data.forEach(item => {
      if (item.id === payload.id) {
        newArray = [...newArray, payload];
      } else {
        newArray = [...newArray, item];
      }
    });
    state.data = newArray;
  },

  SET_TRIPS: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  },
};

const actions = {
    GET_TRIPS_FROM_SERVER: async (context) => {
      let { data } = await Axios.get(`/api/trips`);
      context.commit('SET_TRIPS', data);
    },
    GET_TRIPS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
      if (payload.clear) state.data = [];
      let string = '?';
      for (let key in payload.params) {
        if (!!payload.params[key]) {
          string += `&${key}=${payload.params[key]}`;
        }
      }
      let { data } = await Axios.get(`/api/trips${string}`);
      context.commit('SET_TRIPS', data);
    },
  }
;

export default {
  state,
  getters,
  mutations,
  actions,
};
