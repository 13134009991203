import Axios from 'axios';

const state = {
  event: null,
};

const getters = {
  GET_EVENT: state => state.event,
};

const mutations = {
  SET_EVENT: (state, payload) => state.event = payload.data,
  CLEAR_EVENT: state => state.event = null
};

const actions = {
  GET_EVENT_FROM_SERVER: async ({ commit }, id) => {
    let { data } = await Axios.get(`/api/content/event/${id}`);
    commit('CLEAR_EVENT')
    commit('SET_EVENT', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
