import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
};

const getters = {
  GET_GUIDE: state => state.data,
};

const mutations = {
  SET_GUIDE: (state, payload) => {
    state.data = payload.data
  },
};

const actions = {
  GET_GUIDE_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    let { data } = await Axios.get(`/api/guides/${payload}`);
    context.commit('SET_GUIDE', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
