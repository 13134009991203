import Axios from 'axios';

const state = {
  type: 'agencies',
  cityId: '',
  single: false,
  agency: null,
  agencies: [],
  places: [],
  update: 0,
  location: [],
  cities: [],
  guides: [],
};
const getters = {
  GET_OPERATORS: state => state,
};
const mutations = {
  SET_CITIES_TO_OPERATORS: (state, payload) => state.cities = payload.data,
  SET_CITY_ID_IN_OPERATORS: (state, payload) => {
    state.cityId = payload;
    state.places = [];
    state.places = state.cityId === ''
      ? state.agencies
      : state.agencies.filter(item => item.city_id === state.cityId);
    state.location = state.cityId === ''
      ? [56.48464, 84.947649]
      : state.cities.find(item => item.id === payload).location;
    state.update = state.update += 1;
  },
  SET_AGENCY_TO_OPERATORS: (state, payload) => {
    state.agency = state.agencies.find(item => item.id === payload);
    state.single = payload !== null;
    state.places = [];
    state.places = payload === null
      ? state.agencies
      : state.cityId === ''
        ? [...state.agencies]
        : [...state.agencies.filter(item => item.city_id === state.cityId)];
    state.location = payload !== null
      ? state.agencies.find(item => item.id === payload).location
      : state.cityId === ''
        ? [56.48464, 84.947649]
        : state.cities.find(item => item.id === state.cityId).location;
    state.update = state.update += 1;
  },
  SET_TYPE_TO_OPERATORS: (state, payload) => state.type = payload,
  SET_AGENCIES_TO_OPERATORS: (state, payload) => state.agencies = payload.data,
  SET_GUIDES_TO_OPERATORS: (state, payload) => state.guides = payload.data,
};
const actions = {
  GET_AGENCIES_FROM_SERVER_TO_OPERATORS: async context => {
    let { data } = await Axios.get(`/api/agencies?count=100500`);
    context.commit('SET_AGENCIES_TO_OPERATORS', data);
    context.commit('SET_CITY_ID_IN_OPERATORS', '');
  },
  GET_CITIES_FROM_SERVER_TO_OPERATORS: async context => {
    let { data } = await Axios.get(`/api/cities?count=100500`);
    context.commit('SET_CITIES_TO_OPERATORS', data);
  },
  GET_GUIDES_FROM_SERVER_TO_OPERATORS: async context => {
    let { data } = await Axios.get(`/api/guides?count=100500`);
    context.commit('SET_GUIDES_TO_OPERATORS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
