const state = {
  type: ''
};
const getters = {
  GET_PAGE: state => state
};
const mutations = {
  SET_TYPE_PAGE: (state, payload) => state.type = payload
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
