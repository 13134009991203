const state = {
  width: '',
  height: '',
  mobile: null,
  desktop: null,
};
const getters = {
  GET_VIEWPORT: state => state
};
const mutations = {
  SET_VIEWPORT: state => {
    state.width = document.documentElement.clientWidth;
    state.height = document.documentElement.clientHeight;
    if (document.documentElement.clientWidth < 1110) {
      state.mobile = true;
      state.desktop = false;
    } else {
      state.mobile = false;
      state.desktop = true;
    }
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
