<template>
  <div class="trip-view"
       v-if="$trip">
    <div class="trip-view__wrapper">
      <Section class="trip-view__hero-billboard" limiter>
        <BreadCrumbs :params="{ navigation, color: 'rgba(255,255,255,.58)' }"/>
        <h1 class="trip-view__full-info-title" v-if="$viewport.mobile">{{ trip.title }}</h1>
        <Loader v-if="images.length === 0"/>
        <Slider
            :height="$viewport.desktop ? 450 : 180"
            :items="images"
            :indentation="$viewport.desktop ? 30 : 8"
            class="trip-view__slider"
            component="TripHeroImage"
            :count="1"
            :limiter="$viewport.desktop"
            v-else
        />
        <Row justify="between" :wrap="$viewport.mobile">
          <div :class="$viewport.mobile ? 'column__full' : ''">
            <Column
                :full="$viewport.mobile"
                class="trip-view__short-info"
                :class="$viewport.desktop ? 'grid-3' : ''"
            >
              <Row v-if="subtype" class="trip-view__short-info-row trip-view__short-info-type">
                <div v-html="svgImg()"/>
                <span class="trip-view__short-info-text">{{ subtype }}</span>
              </Row>
              <Row class="trip-view__short-info-row">
                <Icon class="trip-view__short-info-icon" viewport="0 0 20 20" xlink="duration"/>
                <span class="trip-view__short-info-text">{{ $trip.duration }}</span>
              </Row>
              <Row class="trip-view__short-info-row" v-if="$trip.distance">
                <Icon class="trip-view__short-info-icon" viewport="0 0 20 20" xlink="route"/>
                <span class="trip-view__short-info-text">{{ $trip.distance }}</span>
              </Row>
              <Row class="trip-view__short-info-row">
                <Icon class="trip-view__short-info-icon" viewport="0 0 20 20" xlink="complexity"/>
                <span class="trip-view__short-info-text">{{ level }}</span>
              </Row>
            </Column>
          </div>
          <Column :class="$viewport.desktop ? 'grid-8' : ''" :full="$viewport.mobile">
            <Row class="trip-view__full-info-first-row">
              <!--
              <div class="trip-view__full-info-button">
                <Icon class="trip-view__full-info-icon"
                      viewport="0 0 20 20"
                      xlink="bookmark"/>
                <span class="trip-view__full-info-text">В закладки</span>
              </div>
              <div class="trip-view__full-info-button">
                <Icon class="trip-view__full-info-icon"
                      viewport="0 0 20 20"
                      xlink="to-social-network"/>
                <span class="trip-view__full-info-text">Поделиться</span>
              </div>
              -->
            </Row>
            <h1 class="trip-view__full-info-title" v-if="$viewport.desktop">{{ trip.title }}</h1>

            <!--
            <Row class="trip-view__tags" wrap>
              <div class="trip-view__tag">Сельский туризм</div>
              <div class="trip-view__tag">Паломничество</div>
              <div class="trip-view__tag">Круиз</div>
            </Row>
            -->

<!--            <Column>-->
<!--              <Row align="center" class="trip-view__rating-row">-->
<!--                <Rating-->
<!--                    class="trip__rating"-->
<!--                    :params="{-->
<!--                    id: $trip.id,-->
<!--                    type: 'trips',-->
<!--                    rating: $trip.rating,-->
<!--                    currentUserRating: $trip.currentUserRating,-->
<!--                  }"-->
<!--                />-->
<!--              </Row>-->
<!--              <span class="rating-count">-->
<!--                {{ $trip.countRating }}-->
<!--                {{-->
<!--                plural($trip.countRating, [-->
<!--                'турист оценил',-->
<!--                'туриста оценили',-->
<!--                'туристов оценили',-->
<!--                ])-->
<!--                }}-->
<!--                этот объект-->
<!--              </span>-->
<!--            </Column>-->
          </Column>
        </Row>
        <div class="trip-view__play-block">
          <div class="trip-view__play-button"
               v-if="checkAudio"
               v-on:click="openPopupAudios">
            <Icon class="place-view__play-button-icon" xlink="play" viewport="0 0 20 20"/>
          </div>
          <span v-if="checkAudio && $viewport.desktop" class="trip-view__play-button-text">
            нажмите кнопку, чтобы прослушать аудиогид
          </span>
          <span class="place-view__play-button-text" v-if="checkAudio && $viewport.mobile">
            нажмите кнопку, чтобы прослушать аудиогид
          </span>
        </div>
      </Section>

      <Section class="trip-view__visit" limiter>
        <Row
            :full="$viewport.mobile"
            :justify="$viewport.desktop ? 'between' : ''"
            :wrap="$viewport.mobile"
        >
          <Column :full="$viewport.mobile" :class="$viewport.desktop ? 'grid-3' : ''">
            <h2 class="trip-view__visit-title">Где вы побываете</h2>
          </Column>
          <Column
              :full="$viewport.mobile"
              class="trip-view__visit-text"
              :class="$viewport.desktop ? 'grid-8' : ''"
              v-html="$trip.description"
          />
        </Row>
      </Section>

      <DocumentsSection :documents="$trip.documents" />

      <Section
          v-if="$trip.places.length > 0"
          class="trip-view__on-map"
          limiter
      >
        <div class="trip-view__wrapper" :style="{ width: '100%', height: '400px' }">
          <!--  subtype === 'Пешком' / рисуем маршрут-->
          <on-map
              :params="paramsWithCoords"
              :places="places"
              :useNumber="true"
              :isPedestrian="$trip.subtype_id === 1"
          />
        </div>
      </Section>
      <Section
          v-if="$trip.places.length > 0"
          class="trip-view__trip-plan formation-portal-margin-80"
          limiter
      >
        <Row justify="between" :wrap="$viewport.mobile">
          <Column :class="$viewport.desktop ? 'grid-3' : ''" :full="$viewport.mobile">
            <h2 class="trip-view__trip-plan-title">Состав маршрута</h2>
          </Column>
          <Column :class="$viewport.desktop ? 'grid-8' : ''" :full="$viewport.mobile">
            <Row
                :key="point.id"
                class="trip-view__trip-plan-row"
                justify="between"
                v-for="(point, index) in $trip.places"
            >
              <div v-if="$viewport.desktop" class="grid-1"/>
              <TripPlanPoint
                  :class="$viewport.desktop ? 'grid-7' : ''"
                  :params="assign(point, index)"
              />
            </Row>
          </Column>
        </Row>
      </Section>

      <Section class="trip-view__video" limiter v-if="$trip.youtube">
        <Row justify="between" :wrap="$viewport.mobile">
          <Column :class="$viewport.desktop ? 'grid-3' : ''" :full="$viewport.mobile">
            <h2 class="trip-view__trip-plan-title">Видео маршрута</h2>
          </Column>
          <Column :class="$viewport.desktop ? 'grid-8' : ''" :full="$viewport.mobile">
            <VideoYouTube
              class="content-view__video-you-tube"
              :params="{width: '100%', height: '400px', src: $trip.youtube}"
            />
          </Column>
        </Row>
      </Section>

      <Section class="formation-portal-margin-80" limiter>
        <Divider/>
      </Section>
      <Section limiter>
        <GuestReviews
            :params="{ title: 'Отзывы гостей', button: 'Оставить отзыв' }"
            typePage="trips"
        />
      </Section>
      <Section>
        <PopularRoutes :type="$trip.type"/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
  <Section v-else
           limiter>
    <Loader :params="loaderStyle"/>
  </Section>
</template>

<script>
  export default {
    name: 'TripView',
    metaInfo() {
      return {
        title: 'Туристический гид по Томской области',
      };
    },
    components: {
      PopularRoutes: () => import('../components/PopularRoutes/index'),
      TripPlanPoint: () => import('../components/Trip/TripPlanPoint/index'),
    },
    data() {
      return {
        params: {
          zoom: 11,
          sizeMap: {
            width: '100%',
            height: '400px',
          },
        },
      };
    },
    created() {
      this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
    },
    computed: {
      loaderStyle() {
        return {
          style: {
            height: this.$viewport.desktop ? '450px' : '180px',
            margin: this.$viewport.desktop ? '80px 0' : '20px 0',
          },
        };
      },
      subtype() {
        const type = this.$tripSubTypes.find(
          subtype => subtype.id === this.$trip.subtype_id,
        );
        return type ? type.name : '';
      },
      checkAudio() {
        return this.$trip.places.find(item => item.audios.length > 0);
      },
      paramsWithCoords() {
        const newParams = this.params;
        newParams.coords = this.places.length > 0 ? this.places[0].location : [];
        return newParams;
      },
      level() {
        return this.$trip.level && this.$trip.level.title
          ? this.$trip.level.title
          : 'Уровень не указан';
      },
      trip() {
        return this.$trip;
      },
      images() {
        return this.$trip.images;
      },
      places() {
        return this.$trip.places;
      },
      navigation() {
        let secondBLevel = {
          href: '/trips',
          title: 'Маршруты',
        };
        if (this.$trip.type === 'excursion') {
          secondBLevel = {
            href: '/excursions',
            title: 'Экскурсии',
          };
        }
        return [
          {
            href: '/',
            title: 'Главная',
          },
          secondBLevel,
          {
            href: this.$route.path,
            title: this.$trip.title,
          },
        ];
      },
    },
    methods: {
      plural(n, titles) {
        // eslint-disable-next-line no-nested-ternary,max-len
        return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
      },
      updateCountRating() {
        this.$store.dispatch('GET_TRIP_FROM_SERVER', this.$route.params.id);
      },
      assign(point, index) {
        return Object.assign(point, { index: index + 1 });
      },
      openPopupAudios() {
        this.$store.commit('OPEN_POPUP', {
          clear: true,
          width: 800,
          height: '100vh',
          horizontal: 'right',
          vertical: 'center',
          type: 'PopupAudioTrips',
        });
      },
      svgImg() {
        if (!this.$tripSubTypesSVG.length) return '<div></div>';
        const div = document.createElement('div');
        div.innerHTML = this.$tripSubTypesSVG.find(
          svg => svg.id === this.$trip.subtype_id,
        ).img;
        const svg = div.getElementsByTagName('svg')[0];
        svg.setAttribute('width', '20px');
        svg.setAttribute('height', '20px');
        svg.classList.add('trip-view__short-info-icon');
        div.getElementsByTagName('g')
          .forEach((el) => {
            const element = el;
            element.style.fill = '#cdcdcd';
          });
        return div.innerHTML;
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.$store.dispatch('GET_TRIP_FROM_SERVER', to.params.id)
        .then(() => {
          window.scroll(0, 0);
          next();
        })
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    },
  };
</script>
