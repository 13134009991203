<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"
                          :params="{transparent: true}">
            <Row class="account-content__places-header"
                 justify="between">
              <p class="account-content__places-title">Мои объекты</p>
              <Button v-if="$viewport.desktop"
                      color="green"
                      v-on:click.native="$openPopupAddObject">Добавить объект
              </Button>
            </Row>
            <transition name="fade"
                        mode="out-in">
              <Loader v-if="!ready" :params="{style: {height: '400px'}}"/>
              <Row v-else
                   justify="between"
                   wrap>
                <PopularRouteEdit :key="item.id"
                                  :params="item"
                                  :object_type="'place'"
                                  :class="$viewport.desktop ? 'grid-4' : ''"
                                  v-for="item in $my.places"/>
              </Row>
            </transition>
          </AccountContent>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountPlacesView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    this.$http.get('/api/places?user=current&count=100500')
      .then((resolve) => {
        this.$store.commit('SET_MY_PLACES', resolve.data.data);
        this.ready = true;
      });
  },
};
</script>
