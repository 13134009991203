<template>
    <div class="now-view">
        <div class="now-view__wrapper">
            <Section limiter>
                <div class="grid-sm-full grid-md-8 m-md-auto grid-lg-12">
                    <BreadCrumbs :params="{ navigation, color: 'rgba(0,0,0,.58)' }"/>
                    <h1 class="now-view__title-h1">Посетить сейчас</h1>
                </div>
            </Section>
            <Section limiter>
                <div class="grid-sm-full grid-md-8 m-md-auto grid-lg-12">
                    <p class="now-view__content-p">Туристические направления в Томской области пока не являются
                        популярными среди жителей для активного отдыха и туризма, мы лишь начинаем раскрывать
                        туристический потенциал региона. Именно поэтому каждая Ваша поездка, каждый Ваш отзыв и
                        предложение по организации отдыха в Томской области важен для нас.</p>
                    <p class="now-view__content-p">На этой странице мы формируем актуальный реестр туристских маршрутов
                        и объектов Томской области, которые Вы можете посетить самостоятельно или в сопровождении гида.
                        Этот список будет меняться в зависимости от времени года, доступных предложений и Ваших
                        потребностей.</p>
                    <p class="now-view__content-p">Достопримечательности в Томске Вы можете посетить самостоятельно,
                        более подробную информацию об
                        <router-link class="now-view__content-link"
                                     to="/places">объектах
                        </router-link>
                        ,
                        <router-link class="now-view__content-link"
                                     to="/trips">маршрутах
                        </router-link>
                        и
                        <router-link class="now-view__content-link"
                                     to="/excursions">экскурсиях
                        </router-link>
                        можно увидеть на соответствующих страницах. Описания мы дополнили аудиогидами и фотографиями.
                        Сами объекты (музеи, театры, аттракционы и др.) будут открыты по завершении периода изоляции.
                    </p>
                </div>
            </Section>
            <Section limiter>
                <div class="now-view__warning p-sm-20 grid-sm-full grid-md-8 m-md-auto grid-lg-12">
                    <Row justify="around"
                         wrap>
                        <component :is="mobile ? 'Column' : 'Row'"
                                   class="p-md-20 grid-sm-full grid-md-8 grid-lg-6">
                            <div class="grid-md-2 grid-lg-2">
                                <img class="now-view__warning-image"
                                     :src="require(`@/assets/images/svg/covid.svg`)"
                                     alt=""/>
                            </div>
                            <div class="grid-md-5 grid-lg-4">
                                <p class="now-view__warning-p">Во время пандемии COVID-19 соблюдайте
                                    санитарно-эпидемиологические требования и ограничения! Просим Вас по возможности
                                    оставаться дома или уединенно отдыхать в открытых пространствах парков, лесов,
                                    сельских территорий, и не нарушать санитарно-эпидемиологических требований во время
                                    борьбы с COVID-19.</p>
                            </div>
                        </component>
                        <component :is="mobile ? 'Column' : 'Row'"
                                   class="p-md-20 grid-sm-full grid-md-8 grid-lg-6">
                            <div class="grid-md-2 grid-lg-2">
                                <img class="now-view__warning-image"
                                     :src="require(`@/assets/images/svg/mite.svg`)"
                                     alt=""/>
                            </div>
                            <div class="grid-md-5 grid-lg-4">
                                <p class="now-view__warning-p">Напоминаем, что весна и лето – сезон активности клещей.
                                    Не забывайте правильно одеваться и пользоваться репеллентами.</p>
                            </div>
                        </component>
                    </Row>
                </div>
            </Section>
            <Section limiter>
                <h5 class="now-view__title-h5 grid-md-8 m-md-auto">Предлагаемые виды туризма</h5>
                <Tags class="grid-md-8 m-md-auto">
                    <Tag v-for="anchor in anchors"
                         v-on:click.native="$handleScroll(`#${anchor.link}`, !laptop ? 80 : 120)"
                         :key="anchor.id">{{anchor.name}}
                    </Tag>
                </Tags>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/places/30">
                    <Row justify="between"
                         id="family"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.ec6d240aac75785dbdd2a05405277ae2.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское,
                                    Газпромнефть около п. Слобода Вольная недалеко от Зоркальцево.</p>
                                <p>Парковка по Шегарскому тракту возле «Околицы» или возле центрального входа в
                                    парк.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Зоркальцевский, Зоркальцево, Мира 4б</li>
                                    <li>Рубин, Трактовая 9а</li>
                                    <li>Фирменный магазин межениновской птицефабрики, Зоркальцево, Трактовая 35</li>
                                    <li>Лакомка (бездрожжевой хлеб, выпечка), Зоркальцево, Трактовая 27</li>
                                    <li>Все для вас, Зоркальцево, Трактовая 38</li>
                                </ul>
                                <p>Аптека: Зоркальцево, Трактовая 38</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Сельский парк «Околица», Томский район</h3>
                                <p>Территория парка составляет около 16 гектаров и
                                    представляет собой настоящий музей под открытым небом. Здесь есть небольшая
                                    православная часовня, экстрим-парк, мини-ферма.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Сельский парк «Околица» находится в 17 километрах от
                                    Томска в селе Зоркальцево. Добраться можно на автобусе или личном транспорте из
                                    Томска до села Зоркальцево, далее ориентироваться на дорожные указатели.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское,
                                    Газпромнефть около п. Слобода Вольная недалеко от Зоркальцево.</p>
                                <p>Парковка по Шегарскому тракту возле «Околицы» или возле центрального входа в
                                    парк.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Зоркальцевский, Зоркальцево, Мира 4б</li>
                                    <li>Рубин, Трактовая 9а</li>
                                    <li>Фирменный магазин межениновской птицефабрики, Зоркальцево, Трактовая 35</li>
                                    <li>Лакомка (бездрожжевой хлеб, выпечка), Зоркальцево, Трактовая 27</li>
                                    <li>Все для вас, Зоркальцево, Трактовая 38</li>
                                </ul>
                                <p>Аптека: Зоркальцево, Трактовая 38</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>ежедневно с 9:00 до 22:00</p>
                                <h5>Стоимость:</h5>
                                <p>бесплатно</p>
                                <h5>Ограничения:</h5>
                                <p>парк открыт для индивидуальных прогулок, мини-ферма доступна. Экстрим-парк закрыт до
                                    завершения периода самоизоляции.</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/places/290">
                    <Row justify="between"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.2155ac4502343a1c0fcbf758c4830c5c.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское; Газпромнефть около п. Слобода
                                    Вольная недалеко от Зоркальцево; Роснефть возле Мельниково.</p>
                                <p>Парковка по Шегарскому тракту возле «Околицы» или возле центрального входа в
                                    парк.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Пятерочка, Мельниково, ул.Московская,1 (8.00-22.00)</li>
                                </ul>
                                <p>Аптека: Эвалар, Мельниково, ул.Коммунистическая 4</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>База отдыха «Дом рыболова», Кожевниковский район</h3>
                                <p>Кемпинг на пруду. В водоеме водится "экологически чистая рыба" – карпы, белый амур.
                                    Рыбачить можно по договоренности с администрацией базы. Также есть возможность
                                    посетить местный фазанарий. Территория базы обработана от клеща. На территории есть
                                    беседки, можно арендовать мангал.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Кожевниковский район, деревня Сафроновка (Новотроицк)</p>
                                <p>Добраться можно на автомобиле по трассе до села Мельниково и далее в сторону села
                                    Кожевниково 27,5 километра.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское; Газпромнефть около п. Слобода
                                    Вольная недалеко от Зоркальцево; Роснефть возле Мельниково.</p>
                                <p>Парковка по Шегарскому тракту возле «Околицы» или возле центрального входа в
                                    парк.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Пятерочка, Мельниково, ул.Московская,1 (8.00-22.00)</li>
                                </ul>
                                <p>Аптека: Эвалар, Мельниково, ул.Коммунистическая 4</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>круглосуточно, заезд по согласованию (тел.225-122, 8-913-881-22-87)</p>
                                <h5>Стоимость:</h5>
                                <p>платные стоянка, услуги в сфере рыболовства, аренда беседки и принадлежностей
                                    <a class="now-view__entity-link"
                                       target="_blank"
                                       href="http://domribolova.ru/price.html">на сайте.</a>
                                </p>
                                <h5>Ограничения:</h5>
                                <p>аренда домиков недоступно до завершения периода самоизоляции.</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/places/665">
                    <Row justify="between"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.dc4cab4385b1029835d6ebccd08813a4.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское; Газпромнефть около п. Слобода
                                    Вольная недалеко от Зоркальцево; Роснефть возле Мельниково; Роснефть в
                                    Кожевниково.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Ярче, Кожевниково, Ленина 4</li>
                                    <li>Магнит, Кожевниково, Калинина 54</li>
                                </ul>
                                <p>Аптека: Кожевниково, Калинина 62 и Ленина 2</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Маяк в Руян-городе на Оби, Кожевниковский район</h3>
                                <p>Маяк расположен на высоком яру. Рядом лес, река Обь. Обустроенные места для палаток и
                                    отдыха на берегу реки.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Кожевниковский район, рядом с селом Уртам.</p>
                                <p>Добраться можно на автомобиле по трассе 69К-14 до села Кожевниково и на Уртам, до
                                    маяка.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Татнефть в с.Тимирязевское; Газпромнефть около п. Слобода
                                    Вольная недалеко от Зоркальцево; Роснефть возле Мельниково; Роснефть в
                                    Кожевниково.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Ярче, Кожевниково, Ленина 4</li>
                                    <li>Магнит, Кожевниково, Калинина 54</li>
                                </ul>
                                <p>Аптека: Кожевниково, Калинина 62 и Ленина 2</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>круглосуточно</p>
                                <h5>Стоимость:</h5>
                                <p>вход платный на маяк (100 руб.)</p>
                                <h5>Ограничения:</h5>
                                <p>нет</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/places/17">
                    <Row justify="between"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.7e791645a129a2fb91a2827550fe26b3.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть в Томск, Иркутский тракт 167; Татнефть и Роснефть около
                                    Ново-Михайловки.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, д.Ново-Михайловка, ул.Центральная 15а</li>
                                    <li>Армянский шашлычный двор (на вынос!), д.Ново-Михайловка,
                                        ул.Центральная 11/2
                                    </li>
                                </ul>
                                <p>Аптека: Томск, Иркутский тракт 194</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Семилуженский казачий острог, Томский район</h3>
                                <p>Восстановленная по облику казачьего острога XVII века крепость в селе Семилужки,
                                    чуть выше впадения реки Каменки в реку Большую Киргизку. Село было основано
                                    служилыми людьми в 1664 году как деревня Семилужная. Через деревню проходил
                                    Сибирский тракт, здесь была устроена почтовая станция, а само село являлось одним из
                                    центров извозного дела и дворничества на территории Томской области. Сегодня здесь
                                    можно отдохнуть на природе, порыбачить на амура и карася у озера, на береговой
                                    линии. Возможно посмотреть достопримечательности Семилуженского острога.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Томский район, село Семилужки, улица Иркутский тракт.</p>
                                <p>Добраться можно на автомобиле до с.Семилужки (20 км) по трассе Томск-Мариинск 69К-1
                                    или на автобусе «Томск-Асино» с автовокзала.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть в Томск, Иркутский тракт 167; Татнефть и Роснефть около
                                    Ново-Михайловки.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, д.Ново-Михайловка, ул.Центральная 15а</li>
                                    <li>Армянский шашлычный двор (на вынос!), д.Ново-Михайловка,
                                        ул.Центральная 11/2
                                    </li>
                                </ul>
                                <p>Аптека: Томск, Иркутский тракт 194</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>ежедневно с 9:00 до 17:30</p>
                                <h5>Стоимость:</h5>
                                <p>вход бесплатный, предварительно позвонить 89539201453</p>
                                <h5>Ограничения:</h5>
                                <p>посещение экспозиций недоступно до завершения периода самоизоляции</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/trips/39">
                    <Row justify="between"
                         id="active"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.0ffd088d50488a828f93cffee256f3ae.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34; Роснефть, Богашевский тракт 41.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Ярче, с.Богашево, пер.Нагорный 7</li>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                </ul>
                                <p>Аптека: с.Богашево, Мира 7</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Маршрут «Путь к томскому Граалю» (Таловские чаши)</h3>
                                <p>«Таловские чаши» – памятник природы, который находится под охраной государства. Это
                                    известковые образования, по форме похожие на чаши. Они достигают в высоту одного
                                    метра и состоят из известнякового туфа (травертина).</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Томский район, недалеко от поселка Басандайка, что в 45 километрах
                                    от Томска.</p>
                                <p>Добраться до поселка можно добраться на электричке и на автомобиле через Межениновку
                                    (30 километров от Томска – дорога асфальтированная, но от Межениновки до Басандайки
                                    15 километров – практически бездорожье).</p>
                                <p>От поселка до Таловских чаш можно доехать на автомобиле, велосипеде или дойти пешком:
                                    от железнодорожной станции "Площадка 41 км", длина дороги от станции до Таловских
                                    чаш — 3,5 километра. Это обычная полевая дорога, она хорошо заметна и зимой, и
                                    летом.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34; Роснефть, Богашевский тракт 41.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Ярче, с.Богашево, пер.Нагорный 7</li>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                </ul>
                                <p>Аптека: с.Богашево, Мира 7</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы: </h5>
                                <p>круглосуточно</p>
                                <h5>Стоимость:</h5>
                                <p>вход бесплатный</p>
                                <h5>Ограничения:</h5>
                                <p>нет</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/trips/65">
                    <Row justify="between"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.ff2ab9e2fceefa2cc22fc0e0acfc7c61.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                    <li>Ресторан «Кедр» (на вынос!), Аникино, Первый Аникинский переулок 4а</li>
                                </ul>
                                <p>Аптека: Богашевский тракт 24</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Маршрут «Экотропа в Ларинский заказник» в окрестностях села Батурино</h3>
                                <p>Участок переходной зоны от равнинной южной тайги к горной тайге
                                    Кузнецкого Алатау. Экотропа в Ларинском заказнике – это восемь километров с десятью
                                    остановочными площадками, где установлены информационные стенды о местной
                                    растительности.</p>
                                <p>"Звездный ключ" – возможно, самое популярное место заказника. Это памятник природы,
                                    который находится на правом берегу реки Тугояковки в окрестностях села Батурино —
                                    на территории охранной зоны заказника.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Томский район, около с.Вершинино (6 км)</p>
                                <p>Добраться на автобусе по маршруту Томск – Ярское до остановки "Санаторий
                                    Заповедное" и далее – пешком или на автомобиле от Томска до Батурина. От села нужно
                                    ориентироваться по дорожным указателям "Лагерь "Эколог". У въезда есть автостоянка,
                                    по заказнику передвигаются только пешком.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                    <li>Ресторан «Кедр» (на вынос!), Аникино, Первый Аникинский переулок 4а</li>
                                </ul>
                                <p>Аптека: Богашевский тракт 24</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>круглосуточно</p>
                                <h5>Стоимость:</h5>
                                <p>вход бесплатный</p>
                                <h5>Ограничения:</h5>
                                <p>нет</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Divider/>
            <Section class="now-view__entity"
                     limiter>
                <router-link to="/trips/66">
                    <Row justify="between"
                         class="grid-md-8 m-md-auto"
                         wrap>
                        <Column class="now-view__entity-image-holder grid-sm-full grid-md-4 grid-lg-6">
                            <img class="now-view__entity-image"
                                 src="https://tour.tomsk.life/storage/images/extra.9fbbcd91770492a68d7f5702c59358b9.jpg"
                                 alt="">
                            <div v-if="!mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                    <li>Ресторан «Кедр» (на вынос!), Аникино, Первый Аникинский переулок 4а</li>
                                    <li>Ярче, с.Коларово ул.Центральная 2б</li>
                                </ul>
                                <p>Аптека: Богашевский тракт 24</p>
                            </div>
                        </Column>
                        <Column class="now-view__entity-info grid-sm-full grid-md-4 grid-lg-6">
                            <div class="now-view__entity-info-block">
                                <h3>Маршрут «Коларово – Синий Утес – Суровская протока»</h3>
                                <p>Село Коларово было основано в начале XVII века на берегу Томи у скального выступа
                                    Синий Утес и ранее называлось Спасское село. Оно получило свое название от Спасской
                                    церкви, первое упоминание о которой относится к 1644 году. Каменное здание церкви в
                                    стиле барокко было построено в конце XVIII века. Синий утес славен местами и
                                    санаторием «Синий утес» (закрыт на период самоизоляции). Суровская протока – место
                                    отдыха и купания, галечный пляж.</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Место нахождения:</h5>
                                <p>Томская область, Томский район, село Коларово</p>
                                <p>Добраться можно на автомобиле до с.Коларово, Синего утеса или автобусом Томск-Ярское
                                    с автовокзала.</p>
                            </div>
                            <div v-if="mobile" class="now-view__entity-info-block">
                                <h5>Инфраструктура:</h5>
                                <p>Сеть АЗС: Газпромнефть, Богашевский тракт 34.</p>
                                <p>Магазины:</p>
                                <ul>
                                    <li>Абрикос, Богашевский тракт 24</li>
                                    <li>Ресторан «Кедр» (на вынос!), Аникино, Первый Аникинский переулок 4а</li>
                                    <li>Ярче, с.Коларово ул.Центральная 2б</li>
                                </ul>
                                <p>Аптека: Богашевский тракт 24</p>
                            </div>
                            <div class="now-view__entity-info-block">
                                <h5>Режим работы:</h5>
                                <p>круглосуточно</p>
                                <h5>Стоимость:</h5>
                                <p>вход бесплатный</p>
                                <h5>Ограничения:</h5>
                                <p>нет</p>
                            </div>
                        </Column>
                    </Row>
                </router-link>
            </Section>
            <Section class="now-view__white"
                     limiter>
                <p class="now-view__white-p grid-md-8 m-md-auto grid-11">Другие виды туризма на период режима
                    самоизоляции недоступны. Мы
                    еженедельно будем
                    обновлять обзор доступных маршрутов и туров по Томской области.</p>
            </Section>
            <Section :limiter="$viewport.desktop">
                <div class="now-view__form grid-md-8 m-md-auto">
                    <Row justify="around"
                         wrap>
                        <Column class="grid-sm-full grid-md-7 m-md-auto grid-lg-4">
                            <p class="now-view__content-p">Если у Вас появились отзывы или предложения по организации
                                отдыха в Томской области, просим направлять их нам, заполнив форму обратной связи</p>
                        </Column>
                        <Column class="grid-sm-full grid-md-7 m-md-auto grid-lg-6">
                            <h3 class="now-view__title-h3">Отзыв или предложение по организации отдыха в Томской
                                области</h3>
                            <InputComponent component="Column"
                                            :name="name"
                                            id="name"
                                            label="Имя Фамилия"
                                            v-model="name"/>
                            <InputComponent component="Column"
                                            :name="email"
                                            :error="emailError"
                                            id="email"
                                            label="Email"
                                            v-model="email"/>
                            <InputComponent component="Column"
                                            :name="phone"
                                            id="phone"
                                            label="Телефон"
                                            v-model="phone"/>
                            <InputComponent id="message"
                                            v-model="message"
                                            label="Отзыв или предложение"
                                            type="text"
                                            maxChars="700"
                                            multiline/>
                            <div>
                                <Button color="green"
                                        v-on:click.native="postMessage"
                                        :disabled="disabled">Отправить
                                </Button>
                            </div>
                        </Column>
                    </Row>
                </div>
            </Section>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NowView',
  data() {
    return {
      anchors: [
        {
          name: 'семейный',
          link: 'family',
        },
        {
          name: 'активный',
          link: 'active',
        },
        {
          name: 'гастрономический',
          link: 'gastronomic',
        },
        {
          name: 'религиозный',
          link: 'religious',
        },
        {
          name: 'культурный',
          link: 'cultural',
        },
        {
          name: 'оздоровительный',
          link: 'wellness',
        },
        {
          name: 'сельский',
          link: 'rural',
        },
        {
          name: 'событийный',
          link: 'event',
        },
      ],
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/now',
        title: 'Посетить сейчас',
      }],
      name: '',
      email: '',
      emailError: '',
      phone: '',
      message: '',
      disabled: true,
    };
  },
  methods: {
    postMessage() {
      let string = '?';
      if (this.name) string += `name=${this.name}`;
      if (this.email) string += `&email=${this.email}`;
      if (this.phone) string += `&phone=${this.phone}`;
      if (this.message) string += `&message=${this.message}`;
      this.$http.post(`/api/feedback${string}`)
        .catch((error) => {
          if (error.response.data.errors.email) {
            this.emailError = error.response.data.errors.email[0];
          }
        });
      this.name = '';
      this.email = '';
      this.phone = '';
      this.message = '';
    },
  },
  computed: {
    width() {
      return this.$store.getters.GET_VIEWPORT.width;
    },
    mobile() {
      if (this.width < 768) return true;
    },
    tablet() {
      if (this.width >= 768 && this.width < 1110) return true;
    },
    laptop() {
      if (this.width >= 1110) return true;
    },
  },
  watch: {
    email() {
      this.emailError = '';
      this.email.length > 0 && this.message.length > 0 ? this.disabled = false : this.disabled = true;
    },
    message() {
      this.email.length > 0 && this.message.length > 0 ? this.disabled = false : this.disabled = true;
    },
  },
};
</script>
