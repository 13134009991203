const state = {
  openUserMenu: false,
  openSearchBar: false,
  openBurger: false,

};
const getters = {
  GET_HEADER: state => state,
};
const mutations = {
  OPEN_USER_MENU: state => state.openUserMenu = true,
  CLOSE_USER_MENU: state => state.openUserMenu = false,
  TOGGLE_USER_MENU: state => state.openUserMenu = !state.openUserMenu,
  OPEN_SEARCH_BAR: state => state.openSearchBar = true,
  CLOSE_SEARCH_BAR: state => state.openSearchBar = false,
  CLOSE_BURGER: state => state.openBurger = false,
  TOGGLE_BURGER: state => state.openBurger = !state.openBurger,
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
