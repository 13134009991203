<template>
  <Loader v-if="!show"/>
  <div class="content-view" v-else>
    <div class="content-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0,0,0,.58)'}"/>
      </Section>
      <Section limiter>
        <div class="content-view__type-container">
          <div class="content-view__type">{{$type(news.type)}}</div>
          <div class="content-view__badge">
            <div v-html="svgImg()"/>
            {{badgeName}}
          </div>
        </div>
        <h1 class="content-view__title">{{news.title}}</h1>
        <div class="content-view__meta">
          <div class="content-view__meta-date">
            <Icon class="content-view__meta-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            {{news.started_at ? $moment.unix(news.started_at).format(formatDate) :
            $moment.unix(news.created_at).format(formatDate)}}
          </div>
          <div class="content-view__meta-update">
            {{news.place ? news.place.address :news.address}}
          </div>
          <div v-if="news.cost"
               class="content-view__meta-update">Стоимость: {{news.cost}}
          </div>
        </div>
        <div class="content-view__image-holder"
             v-if="news.images">
          <img class="content-view__image"
               :src="news.images[0]"/>
        </div>
        <p class="content-view__caption"
           v-if="$viewport.desktop">{{news.annotation}}</p>
        <div class="content-view__main-text inner-html"
             v-html="news.description"/>
      </Section>
      <Section v-if="news.images && news.images.length > 1"
               :limiter="$viewport.mobile">
        <Slider :height="$viewport.desktop ? 450 : 180"
                :indentation="$viewport.desktop ? 30 : 8"
                :items="news.images.filter((item, index) => index > 0)"
                class="content-view__slider"
                component="TripHeroImage"
                :count="1"
                :limiter="$viewport.desktop"/>
      </Section>
      <Section v-if="place"
               limiter>
        <h2 class="content-view__on-map-title">Событие на карте</h2>
        <OnMap :params="{sizeMap: {
                width: '100%', height: '400px'},
                coords: place.location || news.location}"
               :places="[place]"
               class="content-view__on-map"/>
      </Section>
      <Section limiter>
        <VideoYouTube class="content-view__video-you-tube"
                      :params="{width: '730px', height: '400px', src: news.youtube}"
                      v-if="news.youtube"/>
      </Section>
      <Section limiter>
        <VideoRuTube :source="news.rutube" v-if="news.rutube" />
      </Section>
      <Section>
        <Author :params="news.author"
                class="content-view__author"
                v-if="news.author"/>
      </Section>
      <DocumentsSection :documents="news.documents" />
      <Section limiter>
        <GuestReviews
            :params="{title: 'Комментарии', button: 'Оставить комментарий'}"
            typePage="places"/>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks class="content-view__share-on-social-networks"
                               :title="`${news.title}`"
                               :url="`${url}`"/>
      </Section>
      <Section :limiter="$viewport.mobile">
        <AnotherNews/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewView',
  components: {
    AnotherNews: () => import('../components/AnotherNews'),
  },
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      show: false,
      formatDate: 'DD MMMM YYYY HH:mm',
    };
  },
  created() {
    if (this.news) this.show = true;
  },
  computed: {
    news() {
      return this.$singleNews;
    },
    place() {
      return this?.news?.place;
    },
    badgeName() {
      const Badge = this.$badges.find(badge => badge.id === this.news.badge_id);
      return Badge ? Badge.title : '';
    },
    navigation() {
      return [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/news',
        title: 'Новости',
      }, {
        href: this.$route.path,
        title: this.news.title,
      }];
    },
    url() {
      return this.$host + this.$route.path;
    },
  },
  methods: {
    svgImg() {
      if (!this.$badgesSVG.length || this.news.badge_id === null) {
        return '<div></div>';
      }
      const div = document.createElement('div');
      div.innerHTML = this.$badgesSVG.find(svg => svg.id === this.news.badge_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol')
        .forEach((el) => {
          const element = el;
          // eslint-disable-next-line camelcase
          const badgeIconSettings = this?.news?.badge?.icon_settings || undefined;
          element.style.fill = badgeIconSettings ? badgeIconSettings.color : '#000000';
          element.style.opacity = badgeIconSettings ? badgeIconSettings.transparency : 1;
        });
      return div.innerHTML;
    },
  },
  watch: {
    $route() {
      this.show = false;
      this.$store.dispatch('GET_SINGLE_NEWS_FROM_SERVER', this.$route.params.id)
        // eslint-disable-next-line no-return-assign
        .then(() => this.show = true);
    },
  },
};
</script>
