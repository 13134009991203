<template>
  <div class="places-view">
    <div class="places-view__wrapper">
      <Section>
        <HeroBillboard :params="{meta, navigation, src}"
                       title="Где отдохнуть"
                       description="Хотите хорошо отдохнуть? К вашим услугами все базы отдыха,отели
                       и гостиницы Томской области. Здесь вы найдете подборки мест для активного и
                       культурного отдыха. Познакомитесь со всеми достопримечательностями Томской
                       области: памятниками природы, историческими и архитектурными объектами."
        />
      </Section>
      <Section class="trips-view__all"
               limiter>
        <Row class="trips-view__all-popular-route places-view__types-container"
             wrap>
          <div v-for="type in $types"
               :key="type.id"
               class="trips-view__types"
               :class="type.id === typeId ? 'trips-view__types-active' : ''"
               v-on:click="getPlacesByType(type.id)">{{ type.name }}
          </div>
        </Row>
        <Row class="trips-view__filter-block">
          <span v-if="$viewport.desktop"
                class="trips-view__filter-title">Показывать сначала:</span>
          <span class="trips-view__filter-button"
                v-for="button in filterButton"
                :key="button.id"
                v-on:click="sortPlaces(button.value)"
                :class="button.active ? 'active' : ''">
            {{ button.name }}
          </span>
          <div class="select-container">
            <div
              v-click-outside="closeDistanceSelect"
              class="select select-with-scroll"
              :class="{'select-opened': showDistance }"
              v-on:click="toggleDistanceSelect">
              <Icon class="select-icon"
                    xlink="arrow-navigation"
                    :rotate="showDistance ? 0 : 180"
                    viewport="0 0 20 20"/>
              <div class="selected">
                {{
                  distances.find(d =>
                    d.length === (currentDistance !== null ? currentDistance.length : 0)).title
                }}
              </div>
              <div v-if="showDistance"
                   class="select-list">
                <div v-for="distance in distances"
                     :key="distance.length"
                     v-on:click="selectDistance(distance)"
                     class="select-item">{{ distance.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="select-container">
            <div
                v-click-outside="closeCitySelect"
                class="select select-with-scroll"
                :class="{'select-opened': show }"
                v-on:click="toggleCitySelect">
              <Icon class="select-icon"
                    xlink="arrow-navigation"
                    :rotate="show ? 0 : 180"
                    viewport="0 0 20 20"/>
              <div class="selected">
                {{ cities.find(item => item.id === cityId).name }}
              </div>
              <div v-if="show"
                   class="select-list">
                <div v-for="city in cities"
                     :key="city.id"
                     v-on:click="selectCity(city.id)"
                     class="select-item">{{ city.name }}
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row class="trips-view__all-popular-route"
             wrap>
          <PopularRoute :key="item.id"
                        :params="item"
                        :object_type="'place'"
                        class="trips-view__route"
                        v-for="item in $places.data"/>
        </Row>
        <Row v-if="$places.links.next">
          <Button class="trips-view__more"
                  :disabled="disabled"
                  color="like-link"
                  v-on:click.native="nextPage">Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks class="trips-view__limiter"
                               :url="`${$host}/places`"
                               title="Объекты Томской области"
                               description="Выбирайте:
                       просто гулять по городам Томской области и наслаждаться чудесами Сибири
                       или заглянуть на эту страницу и узнать, в каком направлении это нужно делать.
                       Здесь мы отразили опыт туристов, которые уже посетили наш регион.
                       Вы найдете адреса и контакты музеев, ресторанов,
                       гостиниц, достопримечательностей и даже больниц."/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlacesView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      disabled: false,
      src: 'places.jpg',
      typeId: null,
      meta: [],
      cityId: '',
      show: false,
      showDistance: false,
      currentDistance: null,
      distances: [{
        title: 'Любое расстояние',
        length: 0,
        direction: 'to',
      }, {
        title: 'до 10 км',
        length: 10,
        direction: 'to',
      }, {
        title: 'до 30 км',
        length: 30,
        direction: 'to',
      }, {
        title: 'до 50 км',
        length: 50,
        direction: 'to',
      }, {
        title: 'от 100 км',
        length: 100,
        direction: 'from',
      }],
      filterButton: [{
        name: 'Новые',
        value: 'created_at',
        active: true,
      }, {
        name: 'Популярные',
        value: 'avg',
        active: false,
      }],
    };
  },
  created() {
    this.cityId = this.$globalCitySelected();
    this.getPlaces();
  },
  computed: {
    cities() {
      return [{
        id: '',
        name: 'Все',
        slug: 'region',
      }, ...this.$cities.data];
    },
    navigation() {
      return [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/places',
        title: 'Объекты',
      }];
    },
  },
  methods: {
    getPlaces() {
      this.$store.dispatch('GET_PLACES_FROM_SERVER_BY_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    getParams() {
      const params = {
        count: 6,
        sort: this.filterButton.find(button => button.active).value,
        category_id: this.$places.typeCategory.LEISURE,
      };
      if (this.cityId !== '') {
        params.city_id = this.cityId;
      }
      if (this.typeId) {
        params.category_id = this.typeId;
      }
      if (this.currentDistance !== null) {
        if (this.currentDistance.direction === 'to') {
          params.to_city_center = this.currentDistance.length;
        } else {
          params.from_city_center = this.currentDistance.length;
        }
      }
      return params;
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$places.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_NEXT_PLACES_FROM_SERVER', {
        params,
      })
      // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
    getPlacesByType(typeId) {
      this.typeId = this.typeId !== typeId ? typeId : null;
      this.getPlaces();
    },
    sortPlaces(value) {
      this.filterButton = this.filterButton
        .map(b => Object.assign(b, { active: b.value === value }));
      this.getPlaces();
    },
    selectCity(id) {
      this.cityId = id;
      this.$store.dispatch('GET_CITY_BY_ID', {
        params: {
          city_id: id,
        },
      });
      this.getPlaces();
    },
    selectDistance(distance) {
      this.currentDistance = distance && distance.length ? distance : null;
      this.getPlaces();
    },
    closeCitySelect() {
      this.show = false;
    },
    toggleCitySelect() {
      this.closeDistanceSelect();
      this.show = !this.show;
    },
    toggleDistanceSelect() {
      this.closeCitySelect();
      this.showDistance = !this.showDistance;
    },
    closeDistanceSelect() {
      this.showDistance = false;
    },
  },
};
</script>
