import Vue from 'vue';
import Vuex from 'vuex';
import agency from './agency';
import articles from './articles';
import badges from './badges';
import cities from './cities';
import city from './city';
import comments from './comments';
import event from './event';
import events from './events';
import excursions from './agencies';
import facts from './facts';
import guide from './guide';
import guides from './guides';
import header from './header';
import nearPlace from './nearPlace';
import news from './news';
import operators from './operators';
import page from './page';
import place from './place';
import places from './places';
import popup from './popup';
import search from './search';
import singleNews from './single-news'
import trip from './trip';
import tripSubtypes from './tripSubtypes';
import trips from './trips';
import tripsExcursions from './excursions';
import types from './types';
import user from './user';
import viewport from './viewport';
import contents from './contents';
import flashmob from './flashmob';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    showYandexWidget: false,
  },
  mutations: {
    SHOW_YANDEX_WIDGET: ((state, payload) => {
      state.showYandexWidget = Boolean(payload);
    }),
    SET_ERROR: ((state, payload) => {
      state.error = payload;
    })
  },
  getters: {
    GET_ERROR: state => {
      return state.error;
    },
    GET_YANDEX_WIDGET_VISIBLE: state => state.showYandexWidget,
  },
  actions: {},
  modules: {
    agency,
    articles,
    badges,
    cities,
    city,
    comments,
    event,
    events,
    excursions,
    facts,
    guide,
    guides,
    header,
    nearPlace,
    news,
    flashmob,
    operators,
    page,
    place,
    places,
    popup,
    search,
    singleNews,
    trip,
    tripSubtypes,
    trips,
    tripsExcursions,
    types,
    user,
    viewport,
    contents,
  }
});
