<template>
  <div class="news-view">
    <div class="news-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{ navigation, color: 'rgba(0,0,0,.58)' }"/>
        <div class="about-text" v-html="html"/>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks
            class="trips-view__limiter"
            :url="`${$host}/about`"
            title="О сервисе"
        />
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: 'AboutView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      html: '',
    };
  },
  created() {
    Axios.get('/api/static_content/about')
      .then(
        (response) => {
          const { data } = response;
          this.html = data.data.html;
        },
      );
  },
  computed: {
    navigation() {
      return [
        {
          href: '/',
          title: 'Главная',
        },
        {
          href: '/about',
          title: 'О сервисе',
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang="scss">
  .about-text {
    margin-bottom: 4em;

    h1 {
      font-size: 2.2em;
    }

    h2 {
      font-size: 1.3em;
    }

    h1,
    h2 {
      margin-bottom: 0.75em;
    }

    p {
      line-height: 1.6;
      margin-bottom: 2em;
    }
  }
</style>
