<template>
  <div class="agencies-guides">
    <div class="agencies-guides__wrapper">
      <Row class="agencies-guides__top"
           :wrap="$viewport.mobile"
           justify="between">
        <Row class="agencies-guides__tabs">
          <div class="agencies-guides__tab"
               :class="$operators.type === 'agencies' ? 'agencies-guides__tab-active' : ''"
               v-on:click="toggle('agencies')">Турфирмы
          </div>
          <div class="agencies-guides__tab"
               :class="$operators.type === 'guides' ? 'agencies-guides__tab-active' : ''"
               v-on:click="toggle('guides')">Гиды
          </div>
        </Row>
        <div class="agencies-guides__button-holder">
          <a class="agencies-guides__button button button__green"
             :class="$viewport.mobile ? 'button__full' : ''"
             href="https://account.tomsk.life/account/restxq/organization-add">
            Добавить организацию</a>
        </div>
      </Row>
      <transition name="fade"
                  mode="out-in">
        <component :is="$viewport.desktop ? 'Row' : 'Column'"
                   class="agencies-guides__bottom"
                   v-if="$operators.type === 'agencies'"
                   justify="between">
          <keep-alive>
            <AgenciesGuidesAgency v-if="this.$operators.single"/>
            <AgenciesGuidesAgencies v-else/>
          </keep-alive>
          <Column :class="$viewport.desktop ? 'grid-6' : ''">
            <OnMap :key="$operators.update"
                   :location="$operators.location"
                   :params="{sizeMap: {
                     width: '100%',
                     height: $viewport.desktop ? '750px' : '300px',},}"
                   :places="$operators.places"/>
          </Column>
        </component>
        <Row v-if="$operators.type === 'guides'"
             class="agencies-guides__guides-holder"
             justify="between"
             wrap>
          <GuideCard v-for="guide in $operators.guides"
                     :class="$viewport.desktop ? 'grid-4 -m-h-15' : ''"
                     :key="guide.id"
                     :params="guide"/>
        </Row>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgenciesGuides',
  components: {
    AgenciesGuidesAgencies: () => import('../../components/AgenciesGuides/AgenciesGuidesAgencies'),
    AgenciesGuidesAgency: () => import('../../components/AgenciesGuides/AgenciesGuidesAgency'),
  },
  data() {
    return {
      update: 0,
    };
  },
  created() {
    this.$store.dispatch('GET_CITIES_FROM_SERVER_TO_OPERATORS');
    this.$store.dispatch('GET_GUIDES_FROM_SERVER_TO_OPERATORS');
  },
  methods: {
    toggle(type) {
      this.$store.commit('SET_TYPE_TO_OPERATORS', type);
    },
  },
};
</script>
