<template>
  <div class="municipality-view">
    <div class="municipality-view__wrapper">
      <RegionView v-if="$route.params.municipality === 'region'"/>
      <CityView v-else/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MunicipalityView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  components: {
    CityView: () => import('../views/CityView'),
    RegionView: () => import('../views/RegionView'),
  },
};
</script>
