<template>
  <div class="plots-view">
    <div class="plots-view__wrapper">

    </div>
  </div>
</template>

<script>
export default {
  name: 'PlotsView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
};
</script>
