<template>
    <div class="error-view">
        <div class="error-view__wrapper">
            <Icon class="error-view__background"
                  xlink="geo"
                  height="100vh"
                  viewport="0 0 1440 810"/>
            <div class="error-view__content">
                <h1 class="error-view__title">Страница не найдена</h1>
                <p class="error-view__number">404</p>
                <p class="error-view__description">Произошла ошибка, такой страницы не существует
                    <br> или она находится в стадии разработки.</p>
                <router-link to="/">
                    <Button class="error-view__button"
                            color="main">Перейти на главную</Button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ErrorView',
};
</script>
