<template>
  <div class="guide-view">
    <div class="guide-view__wrapper">
      <Section class="guide-view__top"
               limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0,0,0,.58)'}"/>
        <h1 class="h1 guide-view__h1">Справочник туриста</h1>
      </Section>
      <Section limiter>
        <h2 class="h2 guide-view__h2">Полезная информация</h2>
        <Column class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">Транспортная инфраструктура</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Федеральные автодороги</p>
                  <p class="guide-view__tabs-block-text"> Через Томскую область проходит Федеральная
                    автодорога М53 (участок Новосибирск - Иркутск).</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Дороги регионального значения</p>
                  <p class="guide-view__tabs-block-text">В Томской области — три автодороги
                    регионального значения: «Автодорога Р398» (Томск — Каргала — Колпашево),
                    «Автодорога Р399» (Каргала — Бакчар) и «Автодорога Р400» (Томск — Мариинск).</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Паромные/ледовые переправы</p>
                  <p class="guide-view__tabs-block-text"> В Томской области протекает большое число
                    рек, через нескоторые из них в летний период можно переправиться на пароме,
                    зимой - по ледовой переправе. Одна из самых больших таких переправ находится на
                    реке Обь и соединяет Томск с Колпашево.</p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">Телефоны администраций</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Администрация Томской области</p>
                  <p class="guide-view__tabs-block-text">(3822) 510-001</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Администрация города Томска</p>
                  <p class="guide-view__tabs-block-text">(3822) 99-13-00</p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(2)">
              <span class="guide-view__tabs-header-title">Телефоны экстренных служб (пожарная,
                спасения, полиция, аварийная, скорая)</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen2 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen2"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Единая служба спасения</p>
                  <p class="guide-view__tabs-block-text">01, 101</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Дежурная часть полиции</p>
                  <p class="guide-view__tabs-block-text">02,102</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Скорая помощь</p>
                  <p class="guide-view__tabs-block-text">03, 103</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">МЧС</p>
                  <p class="guide-view__tabs-block-text">51-10-11</p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Оперативно-дежурная служба Томска (отдел
                    оперативного реагирования на аварийные ситуации)</p>
                  <p class="guide-view__tabs-block-text">005, 58-50-35, 112</p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(3)">
              <span class="guide-view__tabs-header-title">Больницы</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen3 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen3"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">Узнать о возможностях томской медицины,
                    в том числе о возможности плановой госпитализации в больницах региона бесплатно
                    по полису ОМС вы можете на специальном сайте-визитке медицинских услуг
                    Томской области <a href="http://lechimvtomske.ru/" target="_blank">lechimvtomske.ru</a>.
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">В Томской области работают круглосуточные
                    «горячие линии» регионального департамента здравоохранения:</p>
                  <p class="guide-view__tabs-block-text">
                    8 (382-2) 516-616 (бесплатно для жителей Томска) <br>
                    8 800 350 88 50 (бесплатно для жителей районов Томской области)
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(4)">
              <span class="guide-view__tabs-header-title">Вокзалы</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen4 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen4"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Железнодорожный вокзал станции Томск-1 города Томска</p>
                  <p class="guide-view__tabs-block-text">
                    Адрес: Томск, Кирова проспект, 70 <br>
                    Справочная: 8 (800) 775-00-00 <br>
                    Режим работы: круглосуточно. <br>
                    С вокзала Томск курсируют поезда прямого сообщения до Москвы, Анапы, Новокузнецка, Барнаула, летом до Адлера, в пригородном - до Тайги и Асино (от 3 до 8 пар электропоездов).
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Автовокзал Томск</p>
                  <p class="guide-view__tabs-block-text">
                    Адрес: пр. Кирова, 68 <br>
                    Сайт: avtovokzal-tomsk.ru <br>
                    Телефон: 8 (3822) 231-488 <br>
                    Режим работы: ежедневно, с 09:00 до 01:00
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Автовокзал Стрежевой</p>
                  <p class="guide-view__tabs-block-text">
                    Адрес: Томская область, г. Стрежевой,  пл. Юбилейная,1 <br>
                    Телефон: 8 (38259) 5-44-44 <br>
                    Организовано регулярное автобусное сообщение по маршрутам № 672 «Стрежевой — Нижневартовск» и «Стрежевой — Александровское».
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(5)">
              <span class="guide-view__tabs-header-title">Аэропорты</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen5 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen5"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Аэропорт Томск</p>
                  <p class="guide-view__tabs-block-text">
                    Адрес: Томская область, Томский район, п. Аэропорт <br>
                    Справочная: 8 (3822) 932-700 <br>
                    Режим работы: ежедневно, 05:00 – 21:00 по томскому времени (МСК +4)
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-title">Аэропорт Стрежевой</p>
                  <p class="guide-view__tabs-block-text">
                  Адрес: Томская область, г. Стрежевой, ул. Авиаторов, 7 <br>
                  Телефон: 8 (3825) 952-303 <br>
                  Режим работы: ежедневно, 05:00 – 21:00 по томскому времени (МСК +4)
                  </p>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(6)">
              <span class="guide-view__tabs-header-title">Информация туристу</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen6 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen6"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="https://www.russiatourism.ru/news/4555/" target="_blank">
                      Памятка для туристов, пользующихся услугами туристических компаний
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="http://methodcentr.ru/otdih_i_ozdorovlenie/new%20fevral%202019%20/23.%20%D0%93%D0%9E%D0%A1%D0%A2%20%D0%A0%2054605-2017.%20%D0%9D%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%B9%20%D0%A4%D0%B5%D0%B4%D0%B5%D1%80%D0%B0%D1%86%D0%B8.pdf" target="_blank">
                      Национальный стандарт РФ «Туристские услуги. Требования по обеспечению безопасности туристов»
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="/storage/files/pamyatka_po_osushchestvleniyu_organizovannyh.pdf" target="_blank">
                      Памятка по осуществлению организованных перевозок групп детей
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="https://www.russiatourism.ru/contents/turistam/informatsiya-dlya-rossiyskikh-turistov-osushchestvlyayushchikh-otdykh-na-territorii-rossii-/" target="_blank">
                      Информация для российских туристов, осуществляющих отдых на территории России
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="https://www.russiatourism.ru/contents/turistam/informatsiya-dlya-vezzhayushchikh-v-rossiyu/vizovyy-rezhim1/" target="_blank">
                      Визовый режим для въезжающих в Россию
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="https://www.russiatourism.ru/contents/turistam/zashchita-prav-turistov/zashita-prav-turistov/" target="_blank">
                      Защита прав туристов
                    </a>
                  </p>
                </div>
                <div class="guide-view__tabs-block">
                  <p class="guide-view__tabs-block-text">
                    <a class="guide-view__tabs-block-text-underline" href="https://depeconom.tomsk.gov.ru/registraziaturgrupp" target="_blank">
                      Регистрация туристской группы или отдельных туристов перед выходом на маршрут
                    </a>
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </Column>
      </Section>
      <Billboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      isOpen0: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
    };
  },
  methods: {
    toggle(id) {
      const content = `isOpen${id}`;
      this[content] = !this[content];
    },
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/guide',
        title: 'Справочник туриста',
      }];
    },
  },
};
</script>
