<template>
  <div class="stud-tourism-view">
    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-1.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Студенческий туризм: чем заняться в Томске?</h2>
          <p>Конечно, гулять!</p>
          <p>Томск — идеальный город для прогулок. Он компактный, так что осмотр центра не займет
            много времени.</p>
          <h2 class="mt-30">Как гулять?</h2>
          <ul>
            <li>пешком;</li>
            <li>на велосипеде;</li>
            <li>на самокате;</li>
          </ul>
          <h2 class="mt-30">Где начать знакомство с городом?</h2>
          <p>Лучше всего с прогулки по проспекту Ленина. Стартовать из
            <router-link to="/places/13" class="st-link" target="_blank">Лагерного сада
            </router-link> и далее — вниз по прямой.
          </p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-2.png`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Что встретится по дороге?</h2>
          <ul>
            <li>старинные корпуса знаменитых томских университетов:
              <router-link to="/places/385" class="st-link" target="_blank">Политеха</router-link>
              ,
              <router-link to="/places/91" class="st-link" target="_blank">ТГУ</router-link>
              ,
              <router-link to="/places/388" class="st-link" target="_blank">СибГМУ</router-link>
              и
              <router-link to="/places/386" class="st-link" target="_blank">ТУСУРа</router-link>
              ;
            </li>
            <li>Университетская роща с занятными памятниками;</li>
            <li>
              <router-link to="/places/922" class="st-link" target="_blank">Научная библиотека ТГУ
              </router-link> с гигантским шрифтовым граффити;
            </li>
            <li>Сибирский ботанический сад;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-3.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>После непременно стоит сделать остановку на площади Ново-Соборной —
            поздороваться со Святой Татьяной, покровительницей студентов. У кого же еще
            попросить удачи на экзаменах, как не у нее?</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-4.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>От Ново-Соборной отправляйтесь неспешным шагом дальше по Ленина, чтобы рассмотреть
            сохранившиеся шедевры старой каменной архитектуры Томска.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-5.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Что смотреть?</h2>
          <ul>
            <li><router-link to="/places/481" class="st-link" target="_blank">торговый дом
              купцов Кухтериных</router-link> (сегодня — мэрия Томска) и дом купца Гадалова;</li>
            <li>напротив — здание первой в Сибири церковно-учительской школы, ныне
              <router-link to="/places/68" class="st-link" target="_blank">музей НКВД
              </router-link>;</li>
            <li>усадьбу золотопромышленника Асташева, где расположен
              <router-link to="/places/392" class="st-link" target="_blank">краеведческий музей
              </router-link>;</li>
            <li><router-link to="/places/487" class="st-link" target="_blank">особняк Флеера
            </router-link>, ныне — ЗАГС и большая кофейня;</li>
            <li><router-link to="/places/476" class="st-link" target="_blank">здание Общественного
              собрания</router-link>, более известное как Дом офицеров;</li>
            <li><router-link to="/places/693" class="st-link" target="_blank">Губернскую аптеку
            </router-link>, в прошлом — торговый дом фирмы «Штоль и Шмитъ»;</li>
            <li>целый квартал старинных зданий, включающий
              <router-link to="/places/696" class="st-link" target="_blank">пассаж Второва
              </router-link>;</li>
            <li><router-link to="/places/589" class="st-link" target="_blank">доходный дом Деева
            </router-link>;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-6.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>Дойдя до набережной Томи, в первую очередь стоит навестить знаменитый
            <router-link to="/places/20" class="st-link" target="_blank">томский памятник Чехову
            </router-link>. Потереть бронзовому классику нос и ручку зонтика и, конечно,
            сфотографироваться на память. А после подняться по самой старой городской улице с
            брусчаткой на Воскресенскую гору, откуда начинался Томск, и увидеть его с высоты.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-7.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Что еще обязательно посмотреть в Томске?</h2>
          <p>Конечно, его деревянную архитектуру. В городе сохранилось немало старинных домов,
            у каждого из которых своя любопытная история.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-8.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <ul>
            <li><router-link to="/places/82" class="st-link" target="_blank">«Дом с жар-птицами»
            </router-link>, <router-link to="/places/14" class="st-link" target="_blank">
              «Дом с шатром»</router-link> и
              <router-link to="/places/83" class="st-link" target="_blank">«Дом с драконами»
              </router-link> на улице Красноармейской;</li>
            <li>особняк архитектора Крячкова на проспекте Кирова, где разместился
              <router-link to="/places/90" class="st-link" target="_blank">Музей деревянного
                зодчества</router-link>;</li>
            <li><router-link to="/places/18" class="st-link" target="_blank">дом архитектора
              Хомича на Белинского</router-link>, с которым связана одна из главным томских
              легенд о писателе Александре Волкове и его «Волшебнике Изумрудного города»;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-9.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>И множество других домов, расположенных на улицах Бакунина, Октябрьской, Шишкова,
            Кузнецова, Карташова, Гагарина и в историческом районе Татарская слобода.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-10.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Томск известен и своими забавными памятниками</h2>
          <ul>
            <li><router-link to="/places/419" class="st-link" target="_blank">любовник на стене
              Музея истории Томска</router-link>, регулярно обновляющий гардероб;</li>
            <li><router-link to="/places/417" class="st-link" target="_blank">забавный младенец
              в капусте</router-link> у здания первого роддома;</li>
            <li><router-link to="/places/416" class="st-link" target="_blank">самая маленькая
              в мире бронзовая лягушка-путешественница</router-link> и
              <router-link to="/places/418" class="st-link" target="_blank">домашние тапочки
              </router-link> у гостиницы «Томск»;</li>
            <li><router-link to="/places/402" class="st-link" target="_blank">волк из мультфильма
              «Жил-был пес»</router-link> с его неповторимым «Щас спою»;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-11.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2 class="mt-30">Куда еще стоит отправиться за запоминающимися фото?</h2>
          <p>На прогулку по местам с городскими граффити и стрит-артом. Каждый год в Томске
            появляются и исчезают десятки граффити. Успеть увидеть особо интересные работы –
            это своего рода везение, которое выпадает не каждому. Яркое и непостоянное городское
            искусство давно стало достопримечательностью молодёжного Томска.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-12.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p class="strong">Топ-5 интересных граффити в центре города, которые стоит посмотреть!</p>
          <p class="strong"><span class="marker"></span>Розы Люксембург, 8</p>
          <p>У работы есть название – «Отверженность» и вполне возможно, что граффити скоро
            официально уберут со стены здания.</p>
          <p class="strong"><span class="marker"></span>Бакунина на пересечении с улицей Обруб</p>
          <p>«Томские мотивы», на которых нашлось место традиционным символам города – лошади
            и деревянному зодчеству.</p>
          <p class="strong"><span class="marker"></span>Пионерский переулок, 3а</p>
          <p>Свежая работа на тему пандемии со стороны трамвайны путей.</p>
          <p class="strong"><span class="marker"></span>пер. Красный 10а</p>
          <p>Отличный фон со старинным автомобилем для ваших креативных фото</p>
          <p class="strong"><span class="marker"></span>Ленина 100/1</p>
          <p>Рисунок старинного здания на старинном здании. Выглядит очень старинно!</p>
        </div>
      </Section>
    </Section>

    <Section>
<!--  <div class="st-poster">-->
<!--    <img :src="require(`@/assets/images/studtourism/st-21.jpg`)" alt="Студенческий туризм">-->
<!--  </div>-->
      <Section limiter>
        <div class="st-content">
          <h2>Чем еще заняться на прогулках?</h2>
          <ul>
            <li><router-link to="/places/133" class="st-link" target="_blank">На площади
              Ново-Соборной</router-link> — перекусить у фонтана и поваляться на траве в
              тени деревьев;</li>
            <li><router-link to="/places/27" class="st-link" target="_blank">в Городском саду
            </router-link>: читать книжки в шезлонгах у романтичного пруда, есть мороженое и
              кататься на аттракционах;</li>
            <li><router-link to="/places/13" class="st-link" target="_blank">в Лагерном саду
            </router-link>: в любое время года — фотографировать с высокого берега меандр
              реки Томи и знакомиться с дружелюбными белками;</li>
            <li><router-link to="/places/303" class="st-link" target="_blank">в Михайловской роще
            </router-link>: найти фундамент старинного каменного фонтана с кедром в центре,
              спуститься к роднику и сделать круг по прогулочной тропе;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-14.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Куда отправиться на прогулку за город?</h2>
          <ul>
            <li><router-link to="/places/30" class="st-link" target="_blank">в парк «Околица»
            </router-link> в селе Зоркальцево под открытым небом, где живут необыкновенные
              деревянные скульптуры, созданные в рамках
              <router-link to="/events/341" class="st-link" target="_blank">томского
                фестиваля «Праздник топора»</router-link>;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-15.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <ul>
            <li><router-link to="/places/1058" class="st-link" target="_blank">в «Парк СССР»
            </router-link> в селе Рыбалово, где, как в сериале «Пищеблок», вас ждут гипсовые
              пионеры с горном и девушки с веслом;</li>
            <li><router-link to="/places/818" class="st-link" target="_blank">в Руян-город на Оби
            </router-link> с его маяком, деревянным храмом и видами на реку;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-16.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Узнавать новое!</h2>
          <p>Томск — город с очень богатой историей. Куда отправиться за интересным и
            таинственным?</p>
          <p>В первую очередь — в
            <router-link to="/places/22" class="st-link" target="_blank">Музей истории Томска
            </router-link>.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-17.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p class="strong">Здесь можно:</p>
          <ul>
            <li>узнать больше о прошлом города;</li>
            <li>подняться на каланчу на здании музея и посмотреть на город в компании с пожарным
              Афанасием;</li>
            <li>познакомиться с кошкой Люсей — признанной Почетной работницей музея;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-18.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>А после спуститься по крутой лестнице с Воскресенки — и сразу попасть в
            <router-link to="/places/399" class="st-link" target="_blank">Первый музей славянской
              мифологии</router-link>. В здании с огромным изразцовым панно на фасаде не только
            хранится коллекция работ художников по мотивам русских сказок, былин и мифов, но и
            проходят выставки и фестивали керамики, кукол и городских проектов.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-19.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Где еще рассказывают интересные истории о Томске?</h2>
          <ul>
            <li>в <router-link to="/places/392" class="st-link" target="_blank">Областном
              краеведческом музее</router-link> — здесь можно узнать много нового о предках
              нынешних сибиряков;</li>
            <li>в <router-link to="/places/9" class="st-link" target="_blank">частном музее
              «Профессорская квартира»</router-link> — здесь познакомят с томским бытом начала
              XX века;</li>
            <li>на <router-link to="/excursions" class="st-link" target="_blank">экскурсиях с
              лучшими томскими гидами</router-link>, которые расскажут о необычных дворах,
              тайнах томских подземелий, жизни университетов и исторических районах города;</li>
            <li>на <router-link to="/trips" class="st-link" target="_blank">сайте
              Гид-путеводитель</router-link>, здесь около 50 маршрутов по Томску и области.
              Выбирайте то, что вам интересно, читайте краткую аннотацию маршрута, двигайтесь
              по объектам на карте, любуйтесь видами и слушайте аудиогид, и кстати, совершенно
              бесплатно;</li>
            <li>с помощью бесплатного QR-квеста
              <a href="https://quiz.etis.online" class="st-link" target="_blank">
                «Креативный Томск»</a>, пройти который можно в любое свободное со
              своего смартфона;</li>
          </ul>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-20.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>А еще всем без исключения гостям города рекомендуется навестить «сибирские
            тропики» — <router-link to="/places/11" class="st-link" target="_blank">Ботанический
              сад ТГУ</router-link>. Здесь всегда жарко, зелено и невероятно красиво. Особенно
            летом, когда постоянно что-нибудь цветет: «орхидея-инопланетянин», «царица ночи»,
            черное мангровое дерево, агавы, глицинии, камелии, сакура и проч.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-22.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Развлекаться!</h2>
          <p>Чем заняться в Томске, когда все достопримечательности и музеи уже осмотрены?</p>
          <p>Можно кататься на велосипедах и самокатах. По городским улицам (только берегите
            пешеходов!) или по зеленым зонам —
            <router-link to="/places/13" class="st-link" target="_blank">террасам Лагерного сада
            </router-link>, <router-link to="/places/717" class="st-link" target="_blank">дорожкам
              Михайловской рощи</router-link>,
            <router-link to="/places/466" class="st-link" target="_blank">стадиона
              «Политехник»</router-link>,
            <router-link to="/places/133" class="st-link" target="_blank">площади Ново-Соборной
            </router-link> и набережной Томи. По соседству с лесными зонами открыты пункты
            проката велосипедов и самокатов.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-13.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <p>Конечно, как и везде, здесь есть кинотеатры, ночные клубы, кафе и кафе, кофейни и
            пекарни. Что стоит попробовать в Томске? Выбор широк — от сибирской классики вроде
            блинов и пельменей до стрит-фуда всех мастей: от бургеров на любой вкус и размер,
            отличной пиццы, до мексиканских тако, китайских воков и вьетнамских фо-бо. А еще,
            конечно, фирменные кедровые конфеты, торт «Томск» и много разных версий кофе,
            чая и лимонадов.</p>
        </div>
      </Section>
    </Section>

    <Section>
      <div class="st-poster">
        <img :src="require(`@/assets/images/studtourism/st-23.jpg`)" alt="Студенческий туризм">
      </div>
      <Section limiter>
        <div class="st-content">
          <h2>Ссылки</h2>
          <div class="st-buttons">
            <a href="https://quiz.etis.online/" target="_blank">
              <Button color="green" :full="$viewport.mobile">КВЕСТ «КРЕАТИВНЫЙ ТОМСК»</Button>
            </a>
            <router-link to="/news/671" target="_blank">
              <Button color="green" :full="$viewport.mobile">ЧТО ПРИВЕЗТИ ИЗ ТОМСКА</Button>
            </router-link>
          </div>
        </div>
      </Section>
    </Section>
  </div>
</template>

<script>
export default {
  name: 'StudTourismView',
};
</script>

<style lang="scss" scoped>
.stud-tourism-view {
  .st-poster {
    img {
      width: 100%;
    }
  }

  .st-content {
    margin: 2rem 0;

    h2 {
      font-size: 36px;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 10px;
      font-size: 18px;
      color: #393939;
      line-height: 28px;

      span.marker {
        $markerSize: 10px;
        display: inline-block;
        height: $markerSize;
        width: $markerSize;
        margin-right: 10px;
        border-bottom: solid 2px #3a465c;
        border-right: solid 2px #3a465c;
      }
    }

    ul {
      padding-left: 1rem;
      list-style: circle;

      li {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .st-link {
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    .st-buttons {
      display: flex;
      flex-wrap: wrap;

      a {
        margin: 0 1rem 1rem 0;
      }
    }
  }
}
</style>
