<template>
  <div class="wall-newspaper">
    <Section limiter>
      <div class="wall-newspaper__title" id="page-header">
        Новый год в Томске и Томской области - афиша мероприятий
      </div>

      <div class="wall-newspaper__nav">
        <a
          :href="`#region-${index}`"
          class="wall-newspaper__nav-item"
          :class="{ '_active': index === currentIndex}"
          v-for="(region, index) in regions"
          :key="index"
        >
          {{ region }}
        </a>
      </div>

      <div class="wall-newspaper__content">
          <div
            class="event-region"
            v-for="(region, regionIndex) in regions"
            :key="regionIndex"
          >
            <div class="event-region__anchor" :id="`region-${regionIndex}`"></div>
            <div class="event-region__name">{{ region }}</div>
            <div class="event-region__list">
              <div
                class="event-region__list-item"
                v-for="event in getEventsByRegion(region)"
                :key="event.id"
              >
                <div class="event-card">
                  <div class="event-card__title">{{ event.title }}</div>
                  <div class="event-card__included" v-if="event.included">
                    <span>Включено:</span> {{ event.included }}
                  </div>
                  <div class="event-card__date">
                    <span>Дата проведения:</span> {{ event.date }}
                  </div>
                  <div class="event-card__link">
                    <span>Ссылка:</span> <strong v-html="parseLinkBlock(event.link)"></strong>
                  </div>
                  <div class="event-card__address">
                    <span>Адрес:</span> {{ event.address }}
                  </div>
                  <div class="event-card__transport">
                    <span>Как добраться:</span> {{ event.transport }}
                  </div>
                  <div class="event-card__hotel" v-if="event.hotel">
                    <span>Отель или гостиница:</span> {{ event.hotel }}
                  </div>
                  <div class="event-card__price">
                    <span>Стоимость:</span> {{ event.price }}
                  </div>
                  <div class="event-card__contacts" v-if="event.contacts">
                    <span>Контакты:</span> {{ event.contacts }}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <transition name="fade">
        <a href="#page-header" class="wall-newspaper__button-to-top" v-show="isButtonToTopVisible">
          <Icon viewport="0 0 20 20" fill="#fff" xlink="arrow-navigation" />
        </a>
      </transition>
    </Section>
  </div>
</template>

<script>
import events from '../assets/json/eventsNY2021.json';

export default {
  data: () => ({
    events,
    currentIndex: 0,
    isButtonToTopVisible: false,
  }),

  methods: {
    parseLinkBlock(text) {
      let result = '';

      if (text) {
        result = text.split(',').map(link => `<a href="${link.trim()}" target="_blank">${link.trim()}</a>`).join(', ');
      }

      return result;
    },
    getEventsByRegion(region) {
      const result = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const event of this.events) {
        if (event.region === region) {
          result.push(event);
        }
      }

      return result;
    },
    checkOnButtonToTopVisible() {
      this.isButtonToTopVisible = document.scrollingElement.scrollTop > (window.innerHeight / 4);
    },
  },

  computed: {
    regions() {
      const result = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const event of this.events) {
        if (!result.includes(event.region)) {
          result.push(event.region);
        }
      }

      return result;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.checkOnButtonToTopVisible);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkOnButtonToTopVisible);
  },
};
</script>

<style lang="scss" scoped>
  $greenColor: #0eb519;
  $darkGreenColor: #00a367;

  .wall-newspaper {
    padding: 50px 0;

    &__title {
      font-size: 60px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: normal;
    }

    &__nav {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0 0.5rem;

      &-item {
        margin: 5px;
        padding: 7px 16px;
        border: 2px solid $greenColor;
        border-radius: 5px;
        opacity: .8;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        color: $greenColor;
        cursor: pointer;

        &._active, &:hover {
          background-color: $greenColor;
          color: white;
        }
      }
    }

    &__content {
      .event-region {
        position: relative;

        &__anchor {
          position: absolute;
          top: -100px;
        }

        &__name {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 1.25rem;
          margin-top: 2rem;
          color: $darkGreenColor;
        }

        &__list {
          &-item {
            .event-card {
              margin-bottom: 1.5rem;
              padding: 1.25rem;
              border-radius: 5px;
              box-shadow: 0 1px 2px 0 rgba(0,0,0,.18),
                0 1px 2px 0 rgba(0,0,0,.04),
                0 2px 6px 0 rgba(0,0,0,.04);

              & > div {
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: normal;
                color: rgba(0,0,0,.75);
                margin-bottom: 0.5rem;

                span {
                  color: rgba(0,0,0,.5);
                  font-weight: bold;
                }
              }

              &__title {
                font-size: 22px !important;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    &__button-to-top {
      $buttonToTopSize: 50px;
      position: fixed;
      right: 2rem;
      bottom: 2rem;
      border-radius: 50%;
      height: $buttonToTopSize;
      width: $buttonToTopSize;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $darkGreenColor;

      svg {
        margin-top: -6px;
      }
    }
  }

  @media (max-width: 1110px) {
    .wall-newspaper {
      padding: 36px 0;

      &__title {
        font-size: 24px;
      }
    }
  }
</style>
