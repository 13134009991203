import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  links: {},
  meta: {}
};

const getters = {
  GET_CONTENTS: state => state
};
const mutations = {
  SET_CONTENTS: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  }
};
const actions = {
  GET_CONTENTS_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/content/`);
    context.commit('SET_CONTENTS', data);
  },
  GET_CONTENTS_FROM_SERVER_BY_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let {data} = await Axios.get(`/api/content${string}`);
    context.commit('SET_CONTENTS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
