import Axios from 'axios';

Axios.defaults.timeout = 180000;
const state = {
  data: ''
};
const getters = {
  GET_AGENCY: state => state.data,
};
const mutations = {
  SET_AGENCY: (state, payload) => state.data = payload.data,
};
const actions = {
  GET_AGENCY_BY_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let { data } = await Axios.get(`/api/agencies/${payload.id}`);
    context.commit('SET_AGENCY', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
