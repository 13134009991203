<template>
  <div class="account-dashboard-agency">
    <div class="account-dashboard-agency__wrapper">
      <Column>
        <Row full>
          <p class="account-dashboard-agency__greeting">Здравствуйте, {{$agency.title}}</p>
        </Row>
        <Row :class="$viewport.desktop ? 'formation-portal-margin-40' : ''"
             :wrap="$viewport.mobile"
             :justify="$viewport.desktop ? 'between' : 'start'">
          <Column class="account-dashboard-agency__cell">
            <span class="account-dashboard-agency__value account-dashboard-agency__green">
              {{$my.excursions.length}}</span>
            <span class="account-dashboard-agency__title">
              {{plural($my.excursions.length, ['Экскурсия','Экскурсии','Экскурсий'])}}</span>
          </Column>
          <Column class="account-dashboard-agency__cell">
            <span class="account-dashboard-agency__value account-dashboard-agency__green">
              {{$my.places.length}}</span>
            <span class="account-dashboard-agency__title">
              {{plural($my.places.length, ['Объект','Объекта','Объектов'])}}
            </span>
          </Column>
          <Column class="account-dashboard-agency__cell">
            <span class="account-dashboard-agency__value">{{0}}</span>
            <span class="account-dashboard-agency__title">
              {{plural(0, ['Организация','Оршанизации','Организаций'])}}
            </span>
          </Column>
          <Column class="account-dashboard-agency__cell">
            <span class="account-dashboard-agency__value">
              {{$my.facts.length}}
            </span>
            <span class="account-dashboard-agency__title">
              {{plural($my.facts.length, ['Факт','Факта','Фактов'])}}
            </span>
          </Column>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDashboardAgency',
  created() {
    this.$store.dispatch('GET_MY_EXCURSIONS_FROM_SERVER');
    this.$store.dispatch('GET_MY_PLACES_FROM_SERVER');
    this.$store.dispatch('GET_MY_FACTS_FROM_SERVER');
    this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER');
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    openPopup() {
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 730 : '100%',
        height: this.$viewport.desktop ? 370 : '100%',
        type: 'PopupAddSomething',
      });
    },
  },
};
</script>
