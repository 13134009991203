<template>
    <div id="app"
         v-on:resize="handleResize($event)">
        <IconSprite/>
        <Header/>
        <ResponseForSearch v-if="$search.data"/>
        <SocialBoard v-if="$viewport.desktop && isSuitableSocialRoute" />
        <div class="content">
            <router-view/>
        </div>
        <Footer/>
        <transition name="fade">
            <Popup v-if="$popup.show"/>
        </transition>
    </div>
</template>

<style lang="scss">
    @import "src/assets/style/main";
</style>

<script>
export default {
  created() {
    this.$store.dispatch('GET_CITIES_FROM_SERVER');
    this.$store.dispatch('GET_TYPES_FROM_SERVER');
    this.$store.dispatch('GET_BADGES_FROM_SERVER');
    const apiToken = this.$route.query.api_token || localStorage.api_token;
    if (apiToken) {
      localStorage.api_token = apiToken;
      this.$store.commit('SET_TOKEN', apiToken);
      this.$http.defaults.headers.common.Authorization = `Bearer ${apiToken}`;
      this.$store.dispatch('WHOAMI');
      if (this.$route.query.api_token) this.$router.replace({ query: null });
    } else {
      window.setTimeout(() => this.$store.commit('SET_LOGOUT_USER'), 1000);
    }
  },
  methods: {
    handleResize() {
      this.$store.commit('SET_VIEWPORT');
    },
    loginListener(msg) {
      if (msg.data.event && !this.$user.is_loading) { // it could be empty
        this.$store.dispatch('WHOAMI')
          .then(() => {
            switch (msg.data.event) {
              case 'login':
                if (this.$user.provider_user_id !== msg.data.uid) {
                  window.document.location.pathname = '/login/keycloak';
                }
                break;
              case 'logout':
                if (this.$user.logged) {
                  this.$store.commit('SET_LOGOUT_USER');
                  this.$store.commit('SET_CLEAR_TOKEN');
                }
                break;
              default:
            }
          })
          .catch(() => {
            switch (msg.data.event) {
              case 'login':
                if (this.$user.provider_user_id !== msg.data.uid) {
                  window.document.location.pathname = '/login/keycloak';
                }
                break;
              default:
            }
          });
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    const iframe = document.createElement('iframe');
    iframe.style = 'width:0;height:0;border:0; border:none;';
    iframe.src = 'https://account.tomsk.life/public/frame.html';

    document.body.appendChild(iframe);
    if (window.addEventListener) {
      window.addEventListener('message', this.loginListener);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.$store.commit('CLOSE_POPUP');
      this.$store.commit('CLEAR_SEARCH');
      this.$store.commit('CLOSE_SEARCH_BAR');
    },
  },
  computed: {
    /**
     * Текущий роут подходит для отображения ссылок на соцсети
     * @returns {boolean}
     */
    isSuitableSocialRoute() {
      if (this.$route.name) {
        if (this.$route.name === 'MunicipalityView') {
          return this.$route.params.municipality === 'region';
        }

        return this.$route.name !== 'NewsView' && this.$route.name !== 'InteractiveMapView';
      }

      return false;
    }
  }
};
</script>
