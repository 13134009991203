<template>
  <div class="trips-view">
    <div class="trips-view__wrapper">
      <Section>
        <HeroBillboard
            :params="{ meta, navigation, src }"
            title="Маршруты Томской области"
            description="Не верьте никому на слово —
                       выберите интересный маршрут и пройдите его сами!
                       Вот увидите, результат поразит вас.
                       На этой странице вы найдете маршрут на любой вкус, возраст и время года.
                       Выбирайте то, что вам интересно, читайте краткую аннотацию маршрута,
                       двигайтесь по объектам на карте,
                       любуйтесь видами и слушайте аудиогид."
        />
      </Section>
      <Section class="trips-view__all" limiter>
        <Row class="trips-view__all-popular-route trips-view__types-container" wrap>
          <div
              v-for="type in subtypes"
              :key="type.id"
              class="trips-view__types"
              :class="type.id === typeId ? 'trips-view__types-active' : ''"
              v-on:click="getTripsByType(type.id)"
          >
            {{ type.name }}
          </div>
        </Row>
        <div class="select-container">
          <div
              v-click-outside="closeSelect"
              class="select select-with-scroll"
              :class="{ 'select-opened': show }"
              v-on:click="toggleSelect"
          >
            <Icon
                class="select-icon"
                xlink="arrow-navigation"
                :rotate="show ? 0 : 180"
                viewport="0 0 20 20"
            />
            <div class="selected">
              {{ cities.find(item => item.id === cityId).name }}
            </div>
            <div v-if="show" class="select-list">
              <div
                  v-for="city in cities"
                  :key="city.id"
                  v-on:click="select(city.id)"
                  class="select-item"
              >
                {{ city.name }}
              </div>
            </div>
          </div>
        </div>
        <Row class="trips-view__all-popular-route" wrap>
          <PopularRoute
              :key="item.id"
              :params="item"
              :object_type="'trip'"
              class="trips-view__route"
              v-for="item in $trips.data"
          />
        </Row>
        <Row v-if="$trips.links.next">
          <Button v-on:click.native="nextPage"
                  :disabled="disabled"
                  class="trips-view__more"
                  color="like-link">Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks
            class="trips-view__limiter"
            :url="`${$host}/trips`"
            title="Маршруты Томской области"
            description="Томская область — это не только огромные залежи газа,
                               нефти и
                       родина российских олигархов, но и край, богатый природными чудесами и
                       красотами. Здесь много кедровых и сосновых лесов, а местные реки и
                       озера богаты рыбой. Ради экологического отдыха сюда приезжают туристы не
                       только из России, но и из зарубежных стран."
        />
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripsView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      disabled: false,
      cityId: '',
      typeId: null,
      show: false,
      src: 'trips.jpg',
      meta: [
        //   {
        //   xlink: 'label',
        //   suptitle: 'от 1 602 ₽ ',
        //   subtitle: 'цены',
        // },
        //   {
        //     xlink: 'mountains',
        //     suptitle: '8',
        //     subtitle: 'направлений',
        // }
      ],
    };
  },
  computed: {
    subtypes() {
      return [
        {
          id: null,
          name: 'Все',
        },
        ...this.$tripSubTypes,
      ];
    },
    cities() {
      return [
        {
          id: '',
          name: 'Все',
          slug: 'region',
        },
        ...this.$cities.data,
      ];
    },
    navigation() {
      return [
        {
          href: '/',
          title: 'Главная',
        },
        {
          href: '/trips',
          title: 'Маршруты',
        },
      ];
    },
  },
  methods: {
    getParams() {
      const params = {
        type: 'route',
        count: 6,
      };
      if (this.cityId !== '') {
        params.city_id = this.cityId;
      }
      if (this.typeId) {
        params.subtype_id = this.typeId;
      }
      return params;
    },
    getTripsByType(typeId) {
      this.typeId = this.typeId !== typeId ? typeId : null;
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$trips.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        params,
      })
      // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
    select(id) {
      this.cityId = id;
      this.$store.dispatch('GET_CITY_BY_ID', {
        params: {
          city_id: id,
        },
      });
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    closeSelect() {
      this.show = false;
    },
    toggleSelect() {
      this.show = !this.show;
    },
  },
  created() {
    this.cityId = this.$globalCitySelected();
    this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: this.getParams(),
    });
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
  },
};
</script>
