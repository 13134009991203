<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"
                          :params="{transparent: true}">
            <p class="account-content__favorites-title">Хочу посетить</p>
            <Row class="account-content__favorites-tabs">
                <span v-for="tab in tabs"
                      :key="tab.id"
                      class="account-content__favorites-tab"
                      v-on:click="check(tab.signature)"
                      :class="currentFilter === tab.signature ?
                      'account-content__favorites-tab-active' : ''">{{tab.title}}</span>
            </Row>
            <Row v-if="$favorites.excursions.length > 0 ||
                       $favorites.places.length > 0 ||
                       $favorites.events.length > 0 ||
                       $favorites.trips.length > 0">
                <span v-if="$favorites.excursions.length > 0"
                      class="account-content__favorites-count">{{$favorites.excursions.length}}
                  {{plural($favorites.excursions.length,
                  ['экскурсия', 'экскурсии', 'экскурсий'])}}</span>

              <span v-if="$favorites.trips.length > 0"
                    class="account-content__favorites-count">{{$favorites.trips.length}}
                  {{plural($favorites.trips.length,
                  ['маршрут', 'маршрута', 'маршрутов'])}}</span>

              <span v-if="$favorites.places.length > 0"
                    class="account-content__favorites-count">{{$favorites.places.length}}
                  {{plural($favorites.places.length, ['объект', 'объекта', 'объектов'])}}</span>

              <span v-if="$favorites.events.length > 0"
                    class="account-content__favorites-count">{{$favorites.events.length}}
                  {{plural($favorites.events.length, ['событие', 'события', 'событий'])}}</span>

            </Row>
            <Row v-else>
              <p class="account-dashboard__empty-favorites">
                Здесь будут отображаться объекты, маршруты и события, добавленные в закладки</p>
            </Row>
            <Row justify="between"
                 wrap>
              <component :is="item.type === 'event' ? 'Card' : 'PopularRoute'"
                         :key="item.id"
                         :params="item"
                         :class="$viewport.desktop ? 'grid-4' : ''"
                         v-for="item in arrays"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountFavoritesView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      tabs: [{
        title: 'Все',
        signature: 'all',
      }, {
        title: 'Объекты',
        signature: 'places',
      }, {
        title: 'Экскурсии',
        signature: 'excursions',
      }, {
        title: 'Маршруты',
        signature: 'trips',
      }, {
        title: 'События',
        signature: 'events',
      }],
      currentFilter: 'all',
    };
  },
  created() {
    this.$store.dispatch('GET_FAVORITES_EVENTS_FROM_SERVER');
    this.$store.dispatch('GET_FAVORITES_EXCURSIONS_FROM_SERVER');
    this.$store.dispatch('GET_FAVORITES_PLACES_FROM_SERVER');
  },
  computed: {
    arrays() {
      let array = [];
      if (this.currentFilter === 'all') {
        array = [...this.$favorites.excursions,
          ...this.$favorites.places,
          ...this.$favorites.events];
      } else {
        array = [...this.$favorites[this.currentFilter]];
      }
      return array;
    },
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    check(signature) {
      this.currentFilter = signature;
    },
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
