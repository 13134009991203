<template>
    <div v-if="$place"
         class="place-view">
        <div class="place-view__wrapper">
            <Section class="place-view__hero-billboard"
                     limiter>
                <BreadCrumbs :params="{navigation, color: 'rgba(255,255,255,.58)'}"/>
                <h1 class="place-view__full-info-title"
                    v-if="$viewport.mobile">{{$place.title}}</h1>
                <Loader v-if="$place.images.length === 0"/>
                <Slider :height="$viewport.desktop ? 450 : 180"
                        :indentation="$viewport.desktop ? 30 : 8"
                        :items="$place.images"
                        class="place-view__slider"
                        component="TripHeroImage"
                        :count="1"
                        :limiter="$viewport.desktop"
                        v-else/>
                <component :is="$viewport.desktop ? 'Row' : 'Column'"
                           justify="between">
                    <div class="place-view__additions"
                         :class="$viewport.desktop ? 'grid-3' : ''">
                        <Column class="place-view__short-info">
                            <div class="place-view__short-info-row">
                                <p class="place-view__short-info-title">Адрес</p>
                                <p class="place-view__short-info-text">{{$place.address}}</p>
                            </div>
                            <div v-for="item in $place.additions"
                                 :key="item.id"
                                 class="place-view__short-info-row">
                                <p class="place-view__short-info-title">{{item.key}}</p>
                                <p class="place-view__short-info-text" v-html="item.value"
                                   v-linkified:options="{format: function(value, type) {
                                  value = value.slice(0, 40) + '…';
                                  return value;
                   }, className: 'place-view__short-info-link'}">
                                </p>
                            </div>
                        </Column>
                      <div class="place-view__map-button" v-if="hasLocation">
                        <router-link :to="{ path: '/interactive-map?object_id=' + $place.id }" class="button button__green header-sign-in__button">
                          показать на карте
                        </router-link>
                      </div>
                    </div>
                    <Column :class="$viewport.desktop ? 'grid-8' : ''">
                        <!--<Row class="place-view__full-info-first-row">-->
                        <!--<div class="place-view__full-info-button">
                          <Icon class="place-view__full-info-icon"
                                viewport="0 0 20 20"
                                xlink="bookmark"/>
                          <span class="place-view__full-info-text">В закладки</span>
                        </div>
                        <div class="place-view__full-info-button">
                          <Icon class="place-view__full-info-icon"
                                viewport="0 0 20 20"
                                xlink="to-social-network"/>
                          <span class="place-view__full-info-text">Поделиться</span>
                        </div>-->
                        <!--</Row>-->
                        <h1 class="place-view__full-info-title"
                            v-if="$viewport.desktop">{{$place.title}}</h1>
<!--                        <Column>-->
<!--                            <Row align="center"-->
<!--                                 class="place-view__rating-row">-->
<!--                                <Rating class="guest-review__rating"-->
<!--                                        :params="{id: $place.id,-->
<!--                        type: 'places',-->
<!--                        rating: $place.rating,-->
<!--                        currentUserRating: $place.currentUserRating}"/>-->
<!--                            </Row>-->
<!--                            <span class="rating-count">{{$place.countRating}} {{plural($place.countRating,-->
<!--                ['турист оценил', 'туриста оценили', 'туристов оценили'])}} этот объект</span>-->
<!--                        </Column>-->
                    </Column>
                </component>
                <div class="place-view__play-block">
                    <div class="place-view__play-button"
                         v-if="$place.audios.length"
                         v-on:click="openPopupAudios">
                        <Icon class="place-view__play-button-icon"
                              xlink="play"
                              viewport="0 0 20 20"/>
                    </div>
                    <span v-if="$place.audios.length" class="place-view__play-button-text">
            нажмите кнопку, чтобы прослушать аудиогид
          </span>
                </div>
            </Section>
            <Section class="place-view__visit"
                     limiter>
                <component :is="$viewport.desktop ? 'Row' : 'Column'"
                           justify="between">
                    <Column :class="$viewport.desktop ? 'grid-3' : ''">
                        <h2 class="place-view__visit-title">Об объекте</h2>
                    </Column>
                    <Column :class="$viewport.desktop ? 'grid-8' : ''"
                            class="place-view__visit-text"
                            v-html="$place.description">
                    </Column>
                </component>
                <Row justify="between"
                     v-if="$place.youtube">
                    <Column :class="$viewport.desktop ? 'grid-3' : ''"/>
                    <Column class="place-view__visit-text"
                            :class="$viewport.desktop ? 'grid-8' : ''">
                        <VideoYouTube v-if="$place.youtube"
                                      class="place-view__video"
                                      :params="{width: $viewport.desktop ? '730px'
                          : '100%', height: $viewport.desktop ? '400px'
                          : '100%', src: $place.youtube}"/>
                    </Column>
                </Row>
            </Section>
            <DocumentsSection :documents="$place.documents" />
            <Section limiter v-if="$place.id != 1429">
                <GuestReviews
                  :params="{title: 'Отзывы гостей', button: 'Оставить отзыв', yandexSearch: $place.title}"
                  typePage="places"
                />
            </Section>
            <Section>
                <NearPlaces/>
            </Section>
            <Section>
                <Billboard/>
            </Section>
        </div>
    </div>
    <Section v-else>
        <Loader :params="loaderStyle"/>
    </Section>
</template>

<script>
import NearPlaces from '../components/NearPlaces/index.vue';

export default {
  name: 'PlaceView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  components: { NearPlaces },
  created() {
    this.$store.dispatch('GET_NEAR_PLACES_FROM_SERVER', this.$route.params.id);
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    openPopupAudios() {
      this.$store.commit('OPEN_POPUP', {
        clear: true,
        width: this.$viewport.desktop ? 800 : '100%',
        height: this.$viewport.desktop ? '100vh' : '100vh',
        horizontal: 'right',
        vertical: 'center',
        type: 'PopupAudioGuide',
      });
    },
    updateCountRating() {
      this.$store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', this.$route.params.id);
    },
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/places',
        title: 'Объекты',
      }, {
        href: this.$route.path,
        title: this.$place.title,
      }];
    },
    loaderStyle() {
      return {
        style: {
          height: this.$viewport.desktop ? '450px' : '180px',
          margin: this.$viewport.desktop ? '80px 0' : '20px 0',
        },
      };
    },
    hasLocation() {
      return this.$place.location && this.$place.location.length > 1;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', to.params.id)
      .then(() => {
        window.scroll(0, 0);
        next();
      })
      .catch((error) => {
        if (error.response.status === 404) next('/error');
      });
  },
};
</script>

<style lang="scss">
  .place-view {
    &__map-button {
      margin-top: 1rem;
      a {
        display: flex;
        width: 100%;
        font-size: 11px;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
