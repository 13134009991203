<template>
  <div class="user-view">
    <div class="user-view__wrapper"></div>
  </div>
</template>

<script>
export default {
  name: 'UserView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
};
</script>
