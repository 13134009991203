<template>
  <div class="interactive-map-view">
    <div class="interactive-map-view__wrapper">
      <div class="interactive-map-view__loader" v-if="showLoader">
        <Icon class="loader-icon"
              xlink="loader"
              viewport="0 0 42 42"
        />
      </div>
      <OnMap ref="map" :places="places" :events="events" :location="location" />
      <div
        v-if="$viewport.mobile"
        class="navigation-map__button-show"
        @click="toggleNavigationMap"
      >
        <Icon class="navigation-map__button-show-icon" viewport="0 0 24 24" xlink="burger-menu" />
      </div>
      <transition name="fade">
        <Column v-if="$viewport.desktop || showNavigationMap" class="navigation-map">
          <div class="navigation-map__wrapper">
            <div class="navigation-map__title">
              Введите название или укажите тип туристических объектов:
            </div>
            <div class="navigation-map__search">
              <input
                type="text"
                ref="mapSearchInput"
                name="search"
                class="navigation-map__search-input"
                placeholder="Название объекта"
                autocomplete="on"
                @keydown.enter="searchPlaceByText"
              />
              <div
                class="navigation-map__search-input_submit"
                @click="searchPlaceByText"
                v-if="$viewport.mobile"
              >
                <Icon
                  viewport="0 0 40 40"
                  xlink="loupe"
                  style="padding-left: 8px; padding-top: 8px; width: 38px; height: 38px"
                  fill="#ffffff"
                />
              </div>
            </div>
            <div class="navigation-map__select-holder">
              <div
                id="cityId"
                class="agencies-guides__list-filter-select select select-with-scroll"
                :class="show ? 'select-opened' : ''"
                v-on:click="toggleSelect"
              >
                <Icon
                  class="select-icon"
                  xlink="arrow-navigation"
                  :rotate="show ? 0 : 180"
                  viewport="0 0 20 20"
                />
                <div class="selected">{{ selectedName }}</div>
                <div v-if="show" class="select-list">
                  <div
                    v-for="city in cities"
                    :key="city.id"
                    v-on:click="select(city.id)"
                    class="select-item"
                  >
                    {{ city.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation-map__checkboxes-holder">
              <div class="navigation-map__section" @click="expandedSection('place')">
                <Icon
                  class="navigation-map__section-icon"
                  xlink="successfulObject"
                  viewport="0 0 105 96"
                />Типы объектов
              </div>
              <div v-show="expandSection === 'place'">
                <Row v-for="type in $types" :key="type.id" class="navigation-map__checkbox-row">
                  <input
                    class="navigation-map__checkbox"
                    type="radio"
                    name="place-radio"
                    :checked="type.id === selectedTypeId"
                    :id="`place-${type.id}`"
                  />
                  <label :for="`place-${type.id}`" @click="togglePlaceType(type.id)">
                    <div class="navigation-map__checkbox-legend">
                      <div
                        v-if="type.image && type.image.includes('.svg')"
                        v-html="svgImg(type.id, type.icon_settings, 'types')"
                      />
                      <img v-else :src="type.image" width="32px" height="32px" />
                      {{ type.name }}
                    </div>
                  </label>
                </Row>
              </div>
            </div>
            <component :is="'Row'" class="navigation-map__buttons-holder" justify="between">
              <!-- <Button v-if="$viewport.desktop" color="green" 
              v-on:click.native="$openPopupAddObject">добавить объект</Button> -->
              <Button color="like-link" v-on:click.native="clearAllFilters">сбросить</Button>
              <Button
                v-if="$viewport.mobile"
                color="like-link"
                v-on:click.native="toggleNavigationMap"
              >закрыть</Button
              >
            </component>
          </div>
        </Column>
      </transition>
    </div>
  </div>
</template>

<script>
import methods from '../methods';

export default {
  name: 'InteractiveMapView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      show: false,
      showLoader: false,
      expandSection: 'place',
      cityId: null,
      dispatches: {
        place: 'GET_PLACES_FROM_SERVER_BY_PARAMS',
        badge: 'GET_EVENTS_FROM_SERVER_WITH_PARAMS',
      },
      dispatchPayloads: {
        place: {
          count: 100500,
          show: 'id,title,address,location,audio,images,type',
          map: 1,
        },
        badge: {},
      },
      selectedTypeId: null,
      showNavigationMap: false,
      currentObjectId: null,
      searchText: '',
    };
  },
  computed: {
    cities() {
      return [
        {
          id: null,
          name: 'Все',
          slug: 'region',
        },
        ...this.$cities.data,
      ];
    },
    places(vm) {
      if (vm.expandSection === 'place') {
        if (this.searchText.length > 2) {
          const searchText = this.searchText.toLowerCase();
          const places = this.selectedTypeId !== null
            ? vm.$places.sorted[this.selectedTypeId]
            : vm.$places.data;
          return places.filter(place => methods.$isset(place.title)
            && place.title.toLowerCase().indexOf(searchText) !== -1);
        }
        if (this.currentObjectId !== null) {
          return vm.$places.data.filter(place => place.id === this.currentObjectId);
        }
        if (this.selectedTypeId !== null) {
          return vm.$places.sorted[this.selectedTypeId];
        }
      }

      return [];
    },
    events: vm => (vm.expandSection === 'badge' ? vm.$events.data : []),
    selectedName: vm => vm.cities.find(item => item.id === vm.cityId).name,
    location(vm) {
      let location = null;
      if (this.cityId !== null) {
        // eslint-disable-next-line prefer-destructuring
        location = vm.$cities.all.find(city => city.id === this.cityId).location;
      }
      if (this.currentObjectId !== null) {
        // eslint-disable-next-line prefer-destructuring
        location = vm.$places.data.find(place => place.id === this.currentObjectId).location;
      }
      return location;
    },
  },
  watch: {
    places(items) {
      if (this.searchText.length > 2 && items && items.length > 0) {
        this.currentObjectId = items[0].id;
      }
    },
  },
  methods: {
    searchPlaceByText() {
      this.searchText = this.$refs.mapSearchInput.value;

      if (this.searchText.length > 2) {
        if (this.$viewport.mobile) {
          this.toggleNavigationMap();
        }
        this.clearTypeFilter();
        this.clearMarkers();
        this.startMarkerVisibleHandle();
      }
    },
    togglePlaceType(typeId) {
      this.clearSearchTextFilter();
      this.clearMarkers();
      if (this.$viewport.mobile) {
        this.toggleNavigationMap();
      }
      this.selectedTypeId = typeId;
      this.startMarkerVisibleHandle();
    },
    startMarkerVisibleHandle() {
      this.showLoader = true;
      const timoutForStopHandler = setTimeout(() => {
        this.showLoader = false;
        clearInterval(this.markerHandleInterval);
      }, this.$viewport.mobile ? 15000 : 60000);
      this.markerHandleInterval = setInterval(() => {
        const mapPlacesContainer = this.$el.querySelector('ymaps[class$="-places-pane"]');
        if (mapPlacesContainer) {
          this.showLoader = false;
          clearInterval(this.markerHandleInterval);
          clearTimeout(timoutForStopHandler);
        }
      }, 500);
    },
    clearMarkers() {
      const mapPlacesContainer = this.$el.querySelector('ymaps[class$="-places-pane"]');
      if (mapPlacesContainer) {
        mapPlacesContainer.innerHTML = '';
      }
    },
    clearAllFilters() {
      this.clearTypeFilter();
      this.clearSearchTextFilter();
    },
    clearTypeFilter() {
      this.currentObjectId = null;
      this.selectedTypeId = null;
      this.$el.querySelectorAll('.navigation-map__checkbox').forEach((radio) => {
        // eslint-disable-next-line no-param-reassign
        radio.checked = false;
      });
    },
    clearSearchTextFilter() {
      this.currentObjectId = null;
      this.searchText = '';
      this.$el.querySelector('.navigation-map__search-input').value = '';
    },
    select(id) {
      this.cityId = id;
      this.$store.dispatch('GET_CITY_BY_ID', {
        params: {
          city_id: id || '',
          count: 100500,
        },
      });
      Object.keys(this.dispatches).forEach((dispatch) => {
        const dataParams = {
          city_id: this.cityId,
          ...this.dispatchPayloads[dispatch],
        };
        this.$store.dispatch(this.dispatches[dispatch], {
          clear: false,
          params: dataParams,
        });
      });
    },
    toggleSelect() {
      this.show = !this.show;
    },
    toggleNavigationMap() {
      this.showNavigationMap = !this.showNavigationMap;
    },
    svgImg(id, opt, entity) {
      if (!this[`$${entity}SVG`].length) return '<div></div>';
      const div = document.createElement('div');
      div.innerHTML = this[`$${entity}SVG`].find(svg => svg.id === id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol').forEach((el) => {
        const element = el;
        element.style.fill = opt.color ? opt.color : '#000000';
        element.style.opacity = opt.transparency || 1;
      });
      return div.innerHTML;
    },
    expandedSection(section) {
      this.expandSection = section;
    },
  },
  created() {
    if (this.$globalCitySelected() === '') {
      this.cityId = null;
    } else {
      this.cityId = this.$globalCitySelected();
    }
    if (this.$viewport.desktop) {
      this.showNavigationMap = true;
    }
    if (this.$route.query.object_id) {
      this.currentObjectId = Number(this.$route.query.object_id);
    }
    if (this.$route.query.type_id) {
      setTimeout(() => this.togglePlaceType(Number(this.$route.query.type_id)), 1000);
    }
    Object.keys(this.dispatches).forEach((dispatch) => {
      this.$store.dispatch(this.dispatches[dispatch], {
        clear: true,
        params: { ...this.dispatchPayloads[dispatch], sorted: true },
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.object_id && this.currentObjectId !== Number(to.query.object_id)) {
      this.currentObjectId = Number(to.query.object_id);
    }
    next();
  },
};
</script>
