import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  sorted: [],
  links: {},
  meta: {},
  typeCategory: {
    LEISURE: 1,
    LOGISTICS: 2,
  },
};

const getters = {
  GET_PLACES: state => state,
};

const mutations = {
  UPDATE_PLACES: (state, payload) => {
    let newArray = [];
    state.data.forEach(item => {
      if (item.id === payload.id) {
        newArray = [...newArray, payload];
      } else {
        newArray = [...newArray, item];
      }
    });
    state.data = newArray;
  },
  SET_PLACES: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  },
  SET_SORTED_PLACES: (state) => {
    const sorted = [];
    state.data.forEach((place) => {
      if (place && place.type) {
        if (!sorted[place.type.id]) {
          sorted[place.type.id] = [];
        }

        sorted[place.type.id].push(place);
      }
    })
    state.sorted = [...state.sorted, ...sorted];
  },
};

const actions = {
  GET_PLACES_FROM_SERVER: async (context) => {
    const { data } = await Axios.get('/api/places');
    context.commit('SET_PLACES', data);
  },
  GET_NEXT_PLACES_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/places${string}`);
    context.commit('SET_PLACES', data);
  },
  GET_ALL_PLACES_FROM_SERVER: async (context) => {
    const { data } = await Axios.get('/api/places?count=9999');
    context.commit('SET_PLACES', data);
  },
  GET_PLACES_FROM_SERVER_BY_PARAMS: async (context, payload) => {
    if (payload.clear) {
      state.data = [];
    }

    let string = '?';
    for (let key in payload.params) {
      if (Boolean(payload.params[key])) {
        string += `&${key}=${payload.params[key]}`;
      }
    }

    let { data } = await Axios.get(`/api/places${string}`);
    context.commit('SET_PLACES', data);

    if (payload.params.sorted) {
      context.commit('SET_SORTED_PLACES');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
