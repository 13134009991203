export default {
  $getTripDistanceText(distanceInt) {
    let text = '';

    if (distanceInt < 1000) {
      text = `${distanceInt} м`;
    } else {
      text = `${Math.round(distanceInt / 1000)} км`;
    }

    return text;
  },
  $postAddExcursion(excursion, formData, config) {
    this.$redirectIfGuest();
    this.$http.post(`/api/trips${excursion}`, formData, config)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddExcursion',
          horizontal: 'right',
        });
      });
  },
  $putAddExcursion(excursion, formData, config, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/trips/${id}/${excursion}`, formData, config)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddExcursion',
          horizontal: 'right',
        });
      });
  },
  $postAddAgencies(agencies) {
    this.$redirectIfGuest();
    this.$http.post(`/api/agencies${agencies}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER'));
  },
  $putAddAgencies(agencies, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/agencies/${id}/${agencies}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER'));
  },
  $postAddObject(place) {
    this.$redirectIfGuest();
    this.$http.post(`/api/places${place}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      });
  },
  $putAddObject(place, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/places/${id}/${place}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_PLACES_FROM_SERVER'));
  },
  $putAddGuide(id, guide) {
    this.$redirectIfGuest();
    this.$http.put(`/api/guides/${id}/${guide}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_GUIDE_FROM_SERVER'));
  },
  $postAddGuide(guide) {
    this.$redirectIfGuest();
    this.$http.post(`/api/guides${guide}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_GUIDE_FROM_SERVER'));
  },
  $postAddFact(fact) {
    this.$redirectIfGuest();
    this.$http.post(`/api/content/fact${fact}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$viewport.desktop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddFact',
          horizontal: 'right',
        });
      });
  },
  $openPopupAddObject(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddObject',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddGuide(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddGuide',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddAgencies(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddAgencies',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddFact() {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddFact',
      horizontal: 'right',
    });
  },
  $openPopupAddComment(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddComment',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddExcursion(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddExcursion',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddTrip(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddTrip',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupChooseMunicipality() {
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.desktop ? 730 : '100%',
      height: this.$viewport.desktop ? 510 : '100vh',
      type: 'ChooseMunicipality',
    });
  },
  $handleScroll(selector, indent) {
    let top = document.querySelector(selector).offsetTop;
    window.scrollTo({
      top: top -= indent || 0,
      behavior: 'smooth',
    });
  },
  $redirectIfGuest() {
    if (!this.$user.logged) {
      document.location.href = '/login/keycloak';
    }
  },
  $getTripsByCity(cityId) {
    this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $getNewsByCity(cityId) {
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $getFactsByCity(cityId) {
    this.$store.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $type(type) {
    let string = '';
    if (type === 'place') string = 'Объект';
    if (type === 'event') string = 'Событие';
    if (type === 'fact') string = 'Факт';
    if (type === 'news') string = 'Новость';
    if (type === 'excursion') string = 'Экскурсия';
    if (type === 'route') string = 'Маршрут';
    if (type === 'terrenkur') string = 'Терренкур';
    return string;
  },
  $shuffle(array) {
    return array.map(a => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map(a => a[1]);
  },
  $globalCitySelected() {
    return (this.$city.id) ? this.$city.id : '';
  },
  $isset(v) {
    try {
      // eslint-disable-next-line no-restricted-globals
      return !((typeof v === 'number' && isNaN(v)) || typeof v === 'undefined' || v === null);
    } catch (e) {
      return false;
    }
  },
  async $getYandexCompaniesByTitle(title) {
    let companies = [];
    const params = {
      apikey: '400ec1af-fe2f-49f5-8bd2-19214cbeb304',
      lang: 'ru_RU',
      text: encodeURI(`Томск ${title}`),
    };

    let yandexApiUrl = 'https://search-maps.yandex.ru/v1/?';
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const param in params) {
      yandexApiUrl += `${param}=${params[param]}&`;
    }
    yandexApiUrl = yandexApiUrl.substr(0, yandexApiUrl.length - 1);

    const response = await fetch(yandexApiUrl);

    if (response.ok) {
      const result = await response.json();

      if (result.features && result.features.length > 0) {
        companies = result.features
          .filter(feature => feature.properties.CompanyMetaData)
          .map((feature) => {
            const { id, name } = feature.properties.CompanyMetaData;
            return { id, name };
          });
      }
    }

    return companies;
  },
};
