export default {
  $host() {
    return process.env.NODE_ENV === 'development' ? 'https://tourism.tomsk.rusatom.dev' : 'https://tour.tomsk.life';
  },
  $token() {
    return this.$store.getters.GET_TOKEN;
  },
  $guide() {
    return this.$store.getters.GET_GUIDE;
  },
  $guides() {
    return this.$store.getters.GET_GUIDES;
  },
  $cities() {
    return this.$store.getters.GET_CITIES;
  },
  $city() {
    return this.$store.getters.GET_CITY;
  },
  $region() {
    return this.$store.getters.GET_REGION;
  },
  $agencies() {
    return this.$store.getters.GET_AGENCIES;
  },
  $nearPlaces() {
    return this.$store.getters.GET_NEAR_PLACES;
  },
  $places() {
    return this.$store.getters.GET_PLACES;
  },
  $place() {
    return this.$store.getters.GET_PLACE;
  },
  $search() {
    return this.$store.getters.GET_RESPONSE;
  },
  $types() {
    return this.$store.getters.GET_TYPES;
  },
  $typesSVG() {
    return this.$store.getters.GET_TYPES_SVG;
  },
  $badges() {
    return this.$store.getters.GET_BADGES;
  },
  $badgesSVG() {
    return this.$store.getters.GET_BADGES_SVG;
  },
  $events() {
    return this.$store.getters.GET_EVENTS;
  },
  $trips() {
    return this.$store.getters.GET_TRIPS;
  },
  $trip() {
    return this.$store.getters.GET_TRIP;
  },
  $tripSubTypes() {
    return this.$store.getters.GET_TRIP_SUB_TYPES;
  },
  $tripSubTypesSVG() {
    return this.$store.getters.GET_TRIP_SUB_TYPES_SVG;
  },
  $content() {
    return this.$store.getters.GET_CONTENT;
  },
  $contents() {
    return this.$store.getters.GET_CONTENTS;
  },
  $articles() {
    return this.$store.getters.GET_ARTICLES;
  },
  $facts() {
    return this.$store.getters.GET_FACTS;
  },
  $popup() {
    return this.$store.getters.GET_POPUP;
  },
  $news() {
    return this.$store.getters.GET_NEWS;
  },
  $user() {
    return this.$store.getters.GET_USER;
  },
  $header() {
    return this.$store.getters.GET_HEADER;
  },
  $viewport() {
    return this.$store.getters.GET_VIEWPORT;
  },
  $favorites() {
    return this.$store.getters.GET_FAVORITES;
  },
  $my() {
    return this.$store.getters.GET_MY;
  },
  $excursions() {
    return this.$store.getters.GET_EXCURSIONS;
  },
  $flashmobs() {
    return this.$store.getters.GET_FLASHMOBS;
  },
  $page() {
    return this.$store.getters.GET_PAGE;
  },
  $comments() {
    return this.$store.getters.GET_COMMENTS;
  },
  $agency() {
    return this.$store.getters.GET_AGENCY;
  },
  $operators() {
    return this.$store.getters.GET_OPERATORS;
  },
  $event() {
    return this.$store.getters.GET_EVENT;
  },
  $singleNews() {
    return this.$store.getters.GET_SINGLE_NEWS;
  },
  $yandexWidgetVisible() {
    return this.$store.getters.GET_YANDEX_WIDGET_VISIBLE;
  },
};
