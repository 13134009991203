import Axios from 'axios';

const state = {
  data: []
};

const getters = {
  GET_CITY: state => state.data,
};

const mutations = {
  SET_CITY: (state, payload) => state.data = payload.data[0],
  SET_CITY_BY_ID:  (state, payload) => state.data = payload.data,
};

const actions = {
  GET_CITY_BY_SLUG: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
          string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/cities${string}`);
    context.commit('SET_CITY', data);
    context.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        city_id: data.data[0].id
      }
    });
    context.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        city_id: data.data[0].id
      }
    });
    context.dispatch('GET_PLACES_FROM_SERVER_BY_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        city_id: data.data[0].id
      }
    });
  },
  GET_CITY_BY_ID: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/cities${string}`);
    context.commit('SET_CITY_BY_ID', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
