import Axios from 'axios';

const state = {
  singleNews: null
};

const getters = {
  GET_SINGLE_NEWS: state => state.singleNews
};

const mutations = {
  SET_SINGLE_NEWS: (state, payload) => state.singleNews = payload,
  CLEAR_SINGLE_NEWS: state => state.singleNews = null,
};

const actions = {
  GET_SINGLE_NEWS_FROM_SERVER: async ({ commit }, id) => {
    let { data } = await Axios.get(`/api/news/${id}`);
    commit('CLEAR_SINGLE_NEWS')
    commit('SET_SINGLE_NEWS', data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
