<template>
  <div class="excursions-view">
    <div class="excursions-view__wrapper">
      <Section>
        <HeroBillboard
            :params="{ meta, navigation, src }"
            title="Туры и экскурсии по Томску и Томской области"
            description="Вас уже ждут в Томской области! Поверьте, вы еще очень
                       долго будете делиться с друзьями впечатлениями, которые получили во время
                       экскурсий наших туристических фирм и гидов. Здесь мы собрали список
                       профессионалов, которые возьмут заботу о вашем путешествии в свои руки."
        />
      </Section>
      <Section class="trips-view__all" limiter>
        <Row class="trips-view__all-popular-route excursions-view__types-container" wrap>
          <div
              v-for="type in subtypes"
              :key="type.id"
              class="trips-view__types"
              :class="{ 'trips-view__types-active': type.id === typeId }"
              v-on:click="getTripsByType(type.id)"
          >
            {{ type.name }}
          </div>
        </Row>
        <div class="select-container">
          <SelectComponent
              id="cityId"
              label="Выберите населенный пункт"
              itemKey="name"
              :index="cityId"
              v-on:change-index="changeIndexCity"
              :array="cities"
          />
        </div>
        <Row class="trips-view__all-popular-route" wrap>
          <PopularRoute
              :key="item.id"
              :params="item"
              class="trips-view__route"
              v-for="item in $excursions.data"
          />
        </Row>
        <Row v-if="$excursions.links.next">
          <Button v-on:click.native="nextPage"
                  :disabled="disabled"
                  class="trips-view__more"
                  color="like-link">Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter class="formation-portal-margin-120">
        <ShareOnSocialNetworks
            class="trips-view__limiter"
            :url="`${$host}/excursions`"
            title="Туры и экскурсии по Томской области"
            description="Вас уже ждут в Томской области! Поверьте, вы еще очень
                               долго будете делиться с друзьями впечатлениями, которые получили во
                               время экскурсий наших туристических фирм и гидов. Здесь мы собрали
                               список профессионалов, которые возьмут заботу о вашем путешествии
                               в свои руки."
        />
      </Section>
      <Section limiter>
        <AgenciesGuides class="formation-portal-margin-120"/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
import AgenciesGuides from '../components/AgenciesGuides/index.vue';

export default {
  name: 'ExcursionsView',
  components: { AgenciesGuides },
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      disabled: false,
      cityId: '',
      typeId: null,
      src: 'excursions.jpg',
      meta: [],
    };
  },
  computed: {
    navigation() {
      return [
        {
          href: '/',
          title: 'Главная',
        },
        {
          href: '/',
          title: 'Путеводитель',
        },
        {
          href: '/',
          title: 'Туры и экскурсии',
        },
      ];
    },
    cities() {
      return [
        {
          id: '',
          name: 'Все',
          slug: 'region',
        },
        ...this.$cities.data,
      ];
    },
    subtypes() {
      return [
        {
          id: null,
          name: 'Все',
        },
        ...this.$tripSubTypes,
      ];
    },
  },
  created() {
    this.cityId = this.$globalCitySelected();
    this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: this.getParams(),
    });
    this.$store.dispatch('GET_AGENCIES_FROM_SERVER');
    this.$store.dispatch('GET_GUIDES_FROM_SERVER');
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
  },
  methods: {
    getParams() {
      const params = {
        type: 'excursion',
        count: 6,
      };
      if (this.cityId !== '') {
        params.city_id = this.cityId;
      }
      if (this.typeId) {
        params.subtype_id = this.typeId;
      }
      return params;
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$excursions.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_NEXT_EXCURSIONS_FROM_SERVER', {
        params,
      })
      // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
    getTripsByType(typeId) {
      this.typeId = this.typeId !== typeId ? typeId : null;
      this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    changeIndexCity(id) {
      this.cityId = id;
      this.$store.dispatch('GET_CITY_BY_ID', {
        params: {
          city_id: id,
        },
      });
      this.$store.dispatch('GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
  },
};
</script>
