import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import AboutView from '../views/AboutView.vue';
import AccountAgenciesView from '../views/Account/AccountAgenciesView.vue';
import AccountAgencyView from '../views/Account/AccountAgencyView.vue';
import AccountBecomeGuideView from '../views/Account/AccountBecomeGuideView.vue';
import AccountExcursionsView from '../views/Account/AccountExcursionsView.vue';
import AccountFactsView from '../views/Account/AccountFactsView.vue';
import AccountFavoritesView from '../views/Account/AccountFavoritesView.vue';
import AccountPlacesView from '../views/Account/AccountPlacesView.vue';
import AccountSettingsView from '../views/Account/AccountSettingsView.vue';
import AccountTripsView from '../views/Account/AccountTripsView.vue';
import AccountView from '../views/Account/AccountView.vue';
import ErrorView from '../views/ErrorView.vue';
import EventView from '../views/EventView.vue';
import EventsView from '../views/EventsView.vue';
import ExcursionsView from '../views/ExcursionsView.vue';
import GuideView from '../views/GuideView.vue';
import HomeView from '../views/HomeView.vue';
import InteractiveMapView from '../views/InteractiveMapView.vue';
import MunicipalityView from '../views/MunicipalityView.vue';
import NewView from '../views/NewView.vue';
import NewsView from '../views/NewsView.vue';
import NowView from '../views/NowView.vue';
import PlaceView from '../views/PlaceView.vue';
import PlacesView from '../views/PlacesView.vue';
import PlotsView from '../views/PlotsView.vue';
import TripView from '../views/TripView.vue';
import TripsView from '../views/TripsView.vue';
import UserView from '../views/UserView.vue';
import GastroCardView from '../views/GastroCardView.vue';
import Quiz from '../views/Quiz/index.vue';
import Quiz2 from '../views/Quiz/index2.vue';

import store from '../store/index';
import LogisticsView from '@/views/LogisticsView';
import FlashMobView from '@/views/FlashMobView';
import WallNewspaper from '@/views/WallNewspaper';
import StudTourismView from '@/views/StudTourismView';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  }, {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz2,
  }, {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
  }, {
    path: '/gastrocard',
    name: 'GastroCardView',
    component: GastroCardView,
  }, {
    path: '/plot',
    name: 'PlotsView',
    component: PlotsView,
  }, {
    path: '/news',
    name: 'NewsView',
    component: NewsView,
  }, {
    path: '/news/:id',
    name: 'NewView',
    component: NewView,
    beforeEnter: (to, from, next) => {
      store.dispatch('GET_SINGLE_NEWS_FROM_SERVER', to.params.id)
        .then(() => next())
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    },
  }, {
    path: '/events',
    name: 'EventsView',
    component: EventsView,
  }, {
    path: '/events/:id',
    name: 'EventView',
    component: EventView,
    beforeEnter: (to, from, next) => {
      store.dispatch('GET_EVENT_FROM_SERVER', to.params.id)
        .then(() => next())
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    }
  }, {
    path: '/studtourism',
    name: 'StudTourismView',
    component: StudTourismView,
  }, {
    path: '/user',
    name: 'UserView',
    component: UserView,
  }, {
    path: '/wall',
    name: 'Wall',
    component: WallNewspaper,
  }, {
    path: '/trips',
    name: 'TripsView',
    component: TripsView,
  }, {
    path: '/routes/:id',
    name: 'TripView',
    component: TripView,
    beforeEnter: (to, from, next) => {
      store.dispatch('GET_TRIP_FROM_SERVER', to.params.id)
        .then(() => next())
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    },
  }, {
    path: '/excursions/:id',
    name: 'ExcursionView',
    component: TripView,
    beforeEnter: (to, from, next) => {
      store.dispatch('GET_TRIP_FROM_SERVER', to.params.id)
        .then(() => next())
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    },
  }, {
    path: '/municipality/:municipality',
    name: 'MunicipalityView',
    component: MunicipalityView,
  }, {
    path: '/account',
    name: 'AccountView',
    component: AccountView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/settings',
    name: 'AccountSettingsView',
    component: AccountSettingsView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/favorites',
    name: 'AccountFavoritesView',
    component: AccountFavoritesView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/excursions',
    name: 'AccountExcursionsView',
    component: AccountExcursionsView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/trips',
    name: 'AccountTripsView',
    component: AccountTripsView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/places',
    name: 'AccountPlacesView',
    component: AccountPlacesView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/facts',
    name: 'AccountFactsView',
    component: AccountFactsView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/agencies',
    name: 'AccountAgenciesView',
    component: AccountAgenciesView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/agency/:id',
    name: 'AccountAgencyView',
    component: AccountAgencyView,
    meta: { requiresAuth: true },
  }, {
    path: '/account/become-a-guide',
    name: 'AccountBecomeGuideView',
    component: AccountBecomeGuideView,
    meta: { requiresAuth: true },
  }, {
    path: '/interactive-map',
    name: 'InteractiveMapView',
    component: InteractiveMapView,
  }, {
    path: '/logistics',
    name: 'LogisticsView',
    component: LogisticsView,
  }, {
    path: '/places',
    name: 'PlacesView',
    component: PlacesView,
  }, {
    path: '/places/:id',
    name: 'PlaceView',
    component: PlaceView,
    beforeEnter: (to, from, next) => {
      store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', to.params.id)
        .then(() => next())
        .catch((error) => {
          if (error.response.status === 404) next('/error');
        });
    },
  }, {
    path: '/guide',
    name: 'GuideView',
    component: GuideView,
  }, {
    path: '/excursions',
    name: 'ExcursionsView',
    component: ExcursionsView,
  }, {
    path: '/flashmob',
    name: 'FlashMobView',
    component: FlashMobView,
  }, /*{
    path: '/excursion',
    name: 'ExcursionView',
    component: ExcursionView,
  },*/ {
    path: '/now',
    name: 'NowView',
    component: NowView,
  },
  {
    path: '/logout',
  },
  {
    path: '*',
    name: 'ErrorView',
    component: ErrorView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
