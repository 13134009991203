<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"
                          :params="{transparent: true}">
            <Row class="account-content__agencies-header"
                 justify="between">
              <p class="account-content__agencies-title">Мой гид</p>
            </Row>
            <Row>
              <p class="account-content__subtitle">На нашем портале вы можете разместить свои услуги
                в качестве гида, если вы хотите поделиться интересной информацией с гостями города и
                заработать, то лучшее решение — разместить свою анкету в нашей базе гидов.</p>
            </Row>
            <Loader v-if="!ready"
                    :params="{style: {height: '355px', margin: '30px 0 0'}}"/>
            <Row v-else
                 justify="between"
                 class="-m-h-15"
                 wrap>
              <NewGuideCard v-if="$my.guide.length === 0"
                            v-on:click.native="$openPopupAddGuide($my.guide)"/>
              <GuideCardEditor :key="item.id"
                               :params="item"
                               :class="$viewport.desktop ? 'grid-4' : ''"
                               v-for="item in [...$my.guide]"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountBecomeGuideView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    this.$http.get('/api/guides?user=current')
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.ready = true;
        if (resolve.data.data.length > 0) {
          this.$store.commit('SET_MY_GUIDE', resolve.data.data[0]);
        }
      });
  },
};
</script>
