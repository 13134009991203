<template>
  <div class="places-view">
    <div class="places-view__wrapper">
      <Section>
        <HeroBillboard :params="{meta, navigation, src}"
                       title="Как добраться в Томскую область?"
                       description="Попасть в Томскую область можно на поезде, автобусе, самолёте,
                       автомобиле, мотоцикле, велосипеде и даже пешком! На этой странице вы найдёте
                       информацию об аэропортах, железнодорожных и автовокзалах. Скорее бронируйте
                       билеты, чудеса Томской области ждут вас!"
        />
      </Section>
      <Section class="trips-view__all" limiter>
        <Row class="trips-view__all-popular-route"
             wrap>
          <PopularRoute :key="item.id"
                        :params="item"
                        :object_type="'place'"
                        class="trips-view__route"
                        v-for="item in $places.data"/>
        </Row>
        <Row v-if="$places.links.next">
          <Button class="trips-view__more"
                  :disabled="disabled"
                  color="like-link"
                  v-on:click.native="nextPage">Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks class="trips-view__limiter"
                               :url="`${$host}/places`"
                               title="Объекты Томской области"
                               description="Выбирайте:
                       просто гулять по городам Томской области и наслаждаться чудесами Сибири
                       или заглянуть на эту страницу и узнать, в каком направлении это нужно делать.
                       Здесь мы отразили опыт туристов, которые уже посетили наш регион.
                       Вы найдете адреса и контакты музеев, ресторанов,
                       гостиниц, достопримечательностей и даже больниц."/>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogisticsView',
  data() {
    return {
      disabled: false,
      src: 'logistics.jpg',
      meta: [],
      cityId: '',
      show: false,
    };
  },
  created() {
    this.cityId = this.$globalCitySelected();
    this.$store.dispatch('GET_PLACES_FROM_SERVER_BY_PARAMS', {
      clear: true,
      params: this.getParams(),
    });
  },
  computed: {
    cities() {
      return [{
        id: '',
        name: 'Все',
        slug: 'region',
      }, ...this.$cities.data];
    },
    navigation() {
      return [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/places',
        title: 'Объекты',
      }];
    },
  },
  methods: {
    getParams() {
      const params = {
        count: 6,
        type_category_id: this.$places.typeCategory.LOGISTICS,
      };
      if (this.cityId !== '') {
        params.city_id = this.cityId;
      }
      return params;
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$places.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_NEXT_PLACES_FROM_SERVER', {
        params,
      })
        // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
  },
};
</script>
