<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <Column>
            <AccountContent :class="$viewport.desktop ? 'grid-8' : ''">
              <AccountDashboardAgency/>
            </AccountContent>
            <div class="account-view__become-guide">
              <FormationPortal
                class="account-view__formation-portal-title"
                title="Сделать организацию турфирмой"
                backgroundPosition="center right">
                <Button v-if="$agency.status === 'new'"
                        color="transparent"
                        v-on:click.native="$openPopupAddAgencies($agency)">Подать заявку
                </Button>
                <Button v-if="$agency.status === 'moderated'"
                        color="transparent"
                        disabled>Заявка на модерации
                </Button>
                <Button v-if="$agency.status === 'active'"
                        color="transparent"
                        v-on:click.native="$openPopupAddAgencies($agency)">Просмотреть профиль
                </Button>
                <Button v-if="$agency.status === 'nonactive'"
                        color="red"
                        v-on:click.native="$openPopupAddAgencies($agency)">Заявка отклонена
                </Button>
              </FormationPortal>
            </div>
          </Column>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
import AccountDashboardAgency from '../../components/Account/AccountDashboardAgency/index.vue';

export default {
  name: 'AccountAgencyView',
  components: { AccountDashboardAgency },
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  created() {
    this.$store.dispatch('GET_AGENCY_BY_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      id: this.$route.params.id,
    });
  },
  watch: {
    $route() {
      this.$store.dispatch('GET_AGENCY_BY_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        id: this.$route.params.id,
      });
    },
  },
};
</script>
