<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"
                          :params="{transparent: true}">
            <Row class="account-content__agencies-header"
                 justify="between">
              <p class="account-content__agencies-title">Мои организации</p>
              <a class="button button__green"
                 href="https://account.tomsk.life/account/restxq/organization-add">
                Добавить организацию</a>
            </Row>
            <Loader v-if="!ready"
                    :params="{style: {height: '355px', margin: '30px 0 0'}}"/>
            <Row v-else
                 class="-m-h-15"
                 justify="between"
                 wrap>
              <AgencyCardEditor :key="item.id"
                                :params="item"
                                v-for="item in $my.agencies"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountAgenciesView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    this.$http.get('/api/agencies?user=current&count=100500')
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.$store.commit('SET_MY_AGENCIES', [...resolve.data.data]);
        this.ready = true;
      });
  },
};
</script>
