import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_AGENCIES: state => state.data
};

const mutations = {
  SET_AGENCIES: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  },
};

const actions = {
  GET_AGENCIES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/agencies?count=100500`);
    context.commit('SET_AGENCIES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
