<template>
  <div class="quiz">
    <div class="quiz__wrapper">
      <Section limiter>
        <h2 class="h2">Квиз «Изумрудный город»</h2>
        <h4 class="h4">Ответьте правильно на все вопросы и получите приз!</h4>
      </Section>

      <Section limiter>
        <div class="quiz__questions">
          <div class="question" v-for="question in questions" :key="question.id">
            <div class="question-text">{{ question.text }}</div>
            <div class="question-options form-check">
              <div class="option form-check" v-for="option in question.items" :key="option.id">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="`option-${question.id}`"
                  :id="`option-${question.id}-${option.id}`"
                  @input="selectOption(question, option)"
                >
                <label class="form-check-label"
                       :class="{'_correct': option.active && option.correct,
                    '_incorrect': option.active && !option.correct }"
                       :for="`option-${question.id}-${option.id}`"
                >
                  {{ option.text }} <span>{{ option.active
                  ? (option.correct ? '- Верный ответ.' : '- Неверный ответ. Попробуйте еще раз')
                  : '' }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="quiz__questions-item">
            <div class="question">
              <div class="question-text">4. Подпишитесь на Telegram-канал <a class="tg-link" href="https://t.me/tomskregionguide" target="_blank">Гид-путеводитель по Томской области</a></div>
              <div class="quarcode">
                <img src="./qrcode.jpg" alt="Гид-путеводитель по Томской области">
              </div>
            </div>
          </div>
        </div>
      </Section>

      <div class="quiz-modal" @click="closeModal" v-if="showModal">
        <div class="quiz-modal__text">
          <p class="h2">Вы успешно прошли квиз!<br>Получите ваш приз!</p>
          <span>(коснитесь экрана, чтобы закрыть)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Quiz2',

  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },

  data: () => ({
    answers: [],
    showModal: false,
    questions: [
      {
        id: 1,
        text: '1. Автор сказки «Волшебник Изумрудного города» А.М. Волков в Томске:',
        items: [
          {
            id: 1,
            correct: false,
            active: false,
            text: 'родился',
          },
          {
            id: 2,
            correct: true,
            active: false,
            text: 'учился',
          },
          {
            id: 3,
            correct: false,
            active: false,
            text: 'женился',
          },
        ],
      },
      {
        id: 2,
        text: '2. Сибирка -  краска, которой красили крыши в Томске в старину была:',
        items: [
          {
            id: 1,
            correct: false,
            active: false,
            text: 'розового цвета',
          },
          {
            id: 2,
            correct: false,
            active: false,
            text: 'синего цвета',
          },
          {
            id: 3,
            correct: true,
            active: false,
            text: 'зеленого цвета',
          },
        ],
      },
      {
        id: 3,
        text: '3. Какая из достопримечательностей Томска вдохновила писателя А.М. Волкова на создание знаменитой сказки?',
        items: [
          {
            id: 1,
            correct: true,
            active: false,
            text: 'Изумрудный дом',
          },
          {
            id: 2,
            correct: false,
            active: false,
            text: 'Изумрудное дерево',
          },
          {
            id: 3,
            correct: false,
            active: false,
            text: 'Изумрудный трамвай',
          },
        ],
      },
    ],
  }),

  methods: {
    selectOption(question, option) {
      question.items = question.items.map(item => {
        item.active = false;
        return item;
      });

      option.active = true;
      this.answers.push(option);

      if (this.answers.length >= 3) {
        let rightAnswersCount = 0;

        this.answers.forEach(answer => {
          if (answer.correct) {
            rightAnswersCount++;
          }
        });

        if (rightAnswersCount === 3) {
          setTimeout(() => {
            this.showModal = true;
          }, 500);
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.answers = [];

      this.questions = this.questions.map(question => {
        question.items = question.items.map(item => {
          item.active = false;
          return item;
        });

        return question;
      });

      this.$el.querySelectorAll('input[type=radio]').forEach(input => {
        input.checked = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz {
  background-image: url("./bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 840px;
  width: 100%;
  padding: 3rem 0;

  h2 {
    margin-bottom: 10px;
  }

  h4 {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .question {
    &-text {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      a {
        font-size: 1.5rem;
        color: #00a367;
      }
    }

    &-options {
      margin-bottom: 2rem;

      .option {
        margin-bottom: 1rem;

        label {
          font-size: 1.25rem;
          cursor: pointer;

          span {
            font-weight: bold;
          }

          &._correct {
            span {
              color: #00a367;
            }
          }

          &._incorrect {
            span {
              color: #ff4e4e;
            }
          }
        }
      }
    }

    .quarcode {
      border-radius: 1rem;
      padding: 2rem;
      background-color: white;
      display: inline-block;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  &-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem;
      max-height: 32vh;
      background-color: white;
      border-radius: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      p {
        text-align: center;
        margin-bottom: 1rem;
      }

      span {
        color: #64728c;
      }
    }
  }
}
</style>
