import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_NEAR_PLACES: state => state
};

const mutations = {
  SET_NEAR_PLACES: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  }
};

const actions = {
  GET_NEAR_PLACES_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/places/${string}`);
    context.commit('SET_NEAR_PLACES', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
