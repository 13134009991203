import Axios from 'axios';

const state = {
  api_token: '',
  user: {
    id: null,
    name: null,
    email: null,
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    avatar: require('./../assets/images/svg/user.svg'),
    logged: false,
    provider_user_id: null,
    is_loading: false
  },
  favorites: {
    excursions: [],
    events: [],
    places: [],
  },
  my: {
    excursions: [],
    places: [],
    facts: [],
    agencies: [],
    guide: [],
  }
};
const getters = {
  GET_FAVORITES: state => state.favorites,
  GET_MY: state => state.my,
  GET_USER: state => state.user,
  GET_TOKEN: state => state.api_token,
};
const mutations = {
  SET_TOKEN: (state, payload) => {
    state.api_token = payload;
  },
  SET_USER: (state, payload) => {
    state.user = {
      id: payload.id,
      name: payload.name,
      email: payload.email,
      email_verified_at: payload.email_verified_at,
      created_at: payload.created_at,
      updated_at: payload.updated_at,
      avatar: payload.avatar || require('./../assets/images/svg/user.svg'),
      logged: !!payload.id,
      provider_user_id: payload.provider_user_id,
    };
  },

  SET_USER_ISLOADING: (state, payload) => state.user.is_loading = payload,

  SET_CLEAR_TOKEN: (state) => {
    localStorage.removeItem('api_token');
    state.api_token = '';
  },
  SET_LOGOUT_USER: (state) => state.user = {
    id: null,
    name: null,
    email: null,
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    avatar: require('./../assets/images/svg/user.svg'),
    logged: false,
    provider_user_id: null,
  },
  SET_MY_GUIDE: (state, payload) => state.my.guide = payload,
  SET_MY_EXCURSIONS: (state, payload) => state.my.excursions = payload,
  SET_MY_FACTS: (state, payload) => state.my.facts = payload,
  SET_MY_PLACES: (state, payload) => {
    state.my.places = payload;
    state.my.places.forEach(item => item.link = `/places/${item.id}`);
  },
  SET_MY_AGENCIES: (state, payload) => {
    state.my.agencies = payload
  },
  SET_FAVORITES_EVENTS: (state, payload) => {
    state.favorites.events = payload;
    //state.favorites.events.forEach(event => event.type = 'Событие');
  },
  SET_FAVORITES_EXCURSIONS: (state, payload) => {
    state.favorites.excursions = payload;
  },
  SET_FAVORITES_TRIPS: (state, payload) => {
    state.favorites.trips = payload;
  },
  SET_FAVORITES_PLACES: (state, payload) => {
    state.favorites.places = payload;
    state.favorites.places.forEach(item => item.link = `/places/${item.id}`);
  },
};
const actions = {
  GET_FAVORITES_EVENTS_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/content/event?favorited`);
    commit('SET_FAVORITES_EVENTS', data.data);
  },
  GET_FAVORITES_EXCURSIONS_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/trips?favorited`);
    commit('SET_FAVORITES_EXCURSIONS', data.data.filter(item => item.type === 'excursion'));
    commit('SET_FAVORITES_TRIPS', data.data.filter(item => item.type !== 'excursion'));
  },
  GET_FAVORITES_PLACES_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/places?favorited`);
    commit('SET_FAVORITES_PLACES', data.data);
  },
  GET_MY_EXCURSIONS_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/trips?user=current&count=100500`);
    commit('SET_MY_EXCURSIONS', data.data);
  },
  GET_MY_PLACES_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/places?user=current&count=100500`);
    commit('SET_MY_PLACES', data.data);
  },
  GET_MY_FACTS_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/content/fact?user=current&count=100500`);
    commit('SET_MY_FACTS', data.data);
  },
  GET_MY_GUIDE_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/guides/?user=current`);
    commit('SET_MY_GUIDE', data.data[0]);
  },
  GET_MY_AGENCIES_FROM_SERVER: async ({ commit }) => {
    let { data } = await Axios.get(`/api/agencies?user=current&count=100500`);
    commit('SET_MY_AGENCIES', data.data);
  },
  WHOAMI: async ({ commit }) => {
    commit('SET_USER_ISLOADING', true)
    let { data } = await Axios.get(`/api/user/whoami`);
    commit('SET_USER', data.data);
    commit('SET_USER_ISLOADING', false)
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
