<template>
  <div class="excursions-view flashmob">
    <div class="excursions-view__wrapper">

      <Section class="flashmod-header" limiter>
        <p>
          Экскурсионный флешмоб - бесплатные экскурсии, творческие мастер-классы и
          тематические лекции. Поучаствовать в них вы сможете 28 августа 2021 года,
          достаточно записаться на сайте (одновременно можно записаться только на одну
          экскурсию).
        </p>
      </Section>

      <Section class="trips-view__all" limiter>
        <Row class="trips-view__all-popular-route" wrap>
          <FlashmobItem
            :key="item.id"
            :params="item"
            class="trips-view__route"
            v-for="item in $flashmobs.data"
          />
        </Row>
        <Row v-if="!$flashmobs.is_last_page">
          <Button v-on:click.native="nextPage"
                  :disabled="disabled"
                  class="trips-view__more"
                  color="like-link">Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter class="formation-portal-margin-120">
        <ShareOnSocialNetworks
          class="trips-view__limiter"
          :url="`${$host}/excursions`"
          title="Туры и экскурсии по Томской области"
          description="Вас уже ждут в Томской области! Поверьте, вы еще очень
                               долго будете делиться с друзьями впечатлениями, которые получили во
                               время экскурсий наших туристических фирм и гидов. Здесь мы собрали
                               список профессионалов, которые возьмут заботу о вашем путешествии
                               в свои руки."
        />
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlashMobView',
  created() {
    this.$store.dispatch('GET_FLASHMOB_EXCURSIONS_WITH_PARAMS', {
      clear: true,
      params: this.getParams(),
    });
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    getParams() {
      return {
        count: 6,
      };
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$excursions.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_FLASHMOB_EXCURSIONS_WITH_PARAMS', {
        params,
      })
        // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .flashmob {
    p {
      font-size: 24px;
      line-height: 32px;
      padding: 50px 0;
    }
  }
</style>
