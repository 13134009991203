<template>
  <div class="news-events">
    <div class="news-events__wrapper">
      <Column class="news-events__limiter">
        <Row align="center"
             class="news-events__title-holder"
             justify="between">
          <h2 class="news-events__title">Популярные объекты</h2>
          <Row align="center"
               class="news-events__see-all">
            <router-link class="news-events__see-all-text"
                         to="/places">{{$viewport.desktop ? 'смотреть ' : ''}}все
            </router-link>
            <Icon class="news-events__see-all-icon"
                  viewport="0 0 20 20"
                  :rotate="90"
                  xlink="arrow-navigation"/>
          </Row>
        </Row>
      </Column>
      <Slider :items="$nearPlaces.data"
              :indentation="$viewport.desktop ? 30 : 8"
              :count="$viewport.desktop ? 3 : 1"
              component="PopularRoute"
              limiter/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NearPlaces',
};
</script>
