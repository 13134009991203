import Axios from 'axios';

Axios.defaults.timeout = 180000;

const apiUrl = 'https://act.tomsk.life';

const storeFlashmobSignUp = function (data) {
  localStorage.setItem('flashmob_sign_up', JSON.stringify(data));
};

const unstoreFlashmobSignUp = function () {
  localStorage.removeItem('flashmob_sign_up');
};

const getFlashmobSignUp = function () {
  const stored = localStorage.getItem('flashmob_sign_up');
  return stored && JSON.parse(stored);
};

const oldFlashmobSignUp = getFlashmobSignUp();
const defaultEventId = oldFlashmobSignUp && oldFlashmobSignUp['event_id']
  ? oldFlashmobSignUp['event_id']
  : null;

const state = {
  data: [],
  signed_event_id: defaultEventId,
  is_last_page: true,
};

const getters = {
  GET_FLASHMOBS: state => state,
};

const mutations = {
  SET_FLASHMOBS: (state, payload) => {
    state.data = [...state.data, ...payload.data];
  },
  ADD_APPOINTMENT: (state, event_id) => {
    state.signed_event_id = event_id;
  },
  REMOVE_APPOINTMENT: (state) => {
    state.signed_event_id = null;
  },
};

const actions = {
  ADD_FLASHMOB_SIGN_UP: async (context, payload) => {
    let string = '?';
    payload['user_hash'] = +new Date();
    for (let key in payload) {
      string += `&${key}=${payload[key]}`;
    }
    let { data } = await Axios.get(`${apiUrl}/api/events/appointment/add${string}`);
    context.commit('ADD_APPOINTMENT', payload.event_id);
    storeFlashmobSignUp(payload);
  },
  REMOVE_FLASHMOB_SIGN_UP: async (context) => {
    let string = '?';
    let payload = getFlashmobSignUp();
    for (let key in payload) {
      string += `&${key}=${payload[key]}`;
    }
    let { data } = await Axios.get(`${apiUrl}/api/events/appointment/remove${string}`);
    context.commit('REMOVE_APPOINTMENT');
    unstoreFlashmobSignUp();
  },
  GET_FLASHMOB_EXCURSIONS_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`${apiUrl}/api/events${string}`);
    context.commit('SET_FLASHMOBS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
