import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  links: {},
  meta: {},
  svg: [],
};
const getters = {
  GET_TRIP_SUB_TYPES: state => state.data,
  GET_TRIP_SUB_TYPES_SVG: state => state.svg,
};
const mutations = {
  SET_TRIP_SUB_TYPES: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  },
  SET_TRIP_SUB_TYPES_SVG: (state, payload) => {
    state.svg = payload;
  },
};

const actions = {
  GET_TRIP_SUB_TYPES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/trip_subtypes`);
    const typeWithSVG = data.data.filter(type => Boolean(type.image.indexOf('.svg') + 1));
    const svg = [];
    for (let t of typeWithSVG) {
      if (process.env.NODE_ENV === 'development') {
        const proxy = 'https://cors-anywhere.herokuapp.com/';
        const resp = await Axios.get(proxy + t.image);
        svg.push({
          id: t.id,
          img: resp.data,
          url: t.image
        });
      } else {
        const resp = await Axios.get(t.image);
        svg.push({
          id: t.id,
          img: resp.data,
          url: t.image
        });
      }
    }
    context.commit('SET_TRIP_SUB_TYPES_SVG', svg);
    context.commit('SET_TRIP_SUB_TYPES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
