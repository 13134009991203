<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <Column>
            <AccountContent :class="$viewport.desktop ? 'grid-8' : ''">
              <AccountDashboard/>
            </AccountContent>
            <div class="account-view__become-guide">
              <FormationPortal
                title="Стать гидом"
                description="На нашем портале вы можете разместить свои услуги в качестве гида"
                backgroundPosition="center right">
                <Button v-if="$my.guide.status === 'new'"
                        color="transparent"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Стать гидом
                </Button>
                <Button v-if="$my.guide.status === 'moderated'"
                        color="transparent"
                        disabled>Заявка на модерации
                </Button>
                <Button v-if="$my.guide.status === 'active'"
                        color="transparent"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Просмотреть профиль
                </Button>
                <Button v-if="$my.guide.status === 'nonactive'"
                        color="red"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Заявка отклонена
                </Button>
              </FormationPortal>
            </div>
          </Column>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
import AccountDashboard from '../../components/Account/AccountDashboard/index.vue';

export default {
  name: 'AccountView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  components: {
    AccountDashboard,
  },
  data() {
    return {
      ready: false,
      guides: [],
    };
  },
  created() {
    this.$http.get('/api/guides?user=current')
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.ready = true;
        this.guides = [...resolve.data.data];
        this.$store.commit('SET_MY_GUIDE', resolve.data.data[0]);
      });
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
