<template>
  <div class="account-dashboard">
    <div class="account-dashboard__wrapper">
      <Column>
        <Row :class="$viewport.desktop ? 'formation-portal-margin-80' : ''"
             justify="between">
          <Column>
            <Row>
              <p class="account-dashboard__greeting">Здравствуйте, {{$user.name}}</p>
            </Row>
            <Row align="center">
              <Icon class="account-dashboard__icon"
                    viewport="0 0 30 30"
                    xlink="location"/>
              <span class="account-dashboard__city">Томск</span>
            </Row>
          </Column>
          <Column>
            <UserAvatar :params="{avatar: $user.avatar, size: $viewport.desktop ? 50 : 40}"/>
          </Column>
        </Row>
        <Divider v-if="$viewport.mobile"
                 class="account-dashboard__divider"/>
        <Row align="center"
             class="formation-portal-margin-30"
             :wrap="$viewport.mobile"
             justify="between">
          <p class="account-dashboard__added-all-time">Добавлено за всё время</p>
          <Button :class="$viewport.desktop ? 'grid-3' : ''"
                  :full="$viewport.mobile"
                  color="green"
                  v-on:click.native="openPopup">добавить
          </Button>
        </Row>
        <Row :class="$viewport.desktop ? 'formation-portal-margin-40' : ''"
             :wrap="$viewport.mobile"
             :justify="$viewport.desktop ? 'between' : 'start'">
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value account-dashboard__green">
              {{$my.excursions.length}}</span>
            <span class="account-dashboard__title">
              {{plural($my.excursions.length, ['Экскурсия','Экскурсии','Экскурсий'])}}</span>
          </Column>
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value account-dashboard__green">
              {{$my.places.length}}</span>
            <span class="account-dashboard__title">
              {{plural($my.places.length, ['Объект','Объекта','Объектов'])}}
            </span>
          </Column>
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value">{{0}}</span>
            <span class="account-dashboard__title">
              {{plural(0, ['Организация','Оршанизации','Организаций'])}}
            </span>
          </Column>
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value">
              {{$my.facts.length}}
            </span>
            <span class="account-dashboard__title">
              {{plural($my.facts.length, ['Факт','Факта','Фактов'])}}
            </span>
          </Column>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDashboard',
  created() {
    this.$store.dispatch('GET_MY_EXCURSIONS_FROM_SERVER');
    this.$store.dispatch('GET_MY_PLACES_FROM_SERVER');
    this.$store.dispatch('GET_MY_FACTS_FROM_SERVER');
    this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER');
  },
  mounted() {
    if (this.$route.query.params && this.$route.query.params === 'add') {
      setTimeout(() => {
        this.openPopup();
      }, 1000);
    }
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    openPopup() {
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 730 : '100%',
        height: this.$viewport.desktop ? 370 : '100%',
        type: 'PopupAddSomething',
      });
    },
  },
};
</script>
