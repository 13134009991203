import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
  svg: [],
};

const getters = {
  GET_BADGES: state => state.data,
  GET_BADGES_SVG: state => state.svg,
};

const mutations = {
  SET_BADGES: (state, payload) => {
    state.data = payload.data;
  },
  SET_BADGES_SVG: (state, payload) => {
    state.svg = payload;
  },
};

const actions = {
  GET_BADGES_FROM_SERVER: async (context) => {
    const { data } = await Axios.get(`/api/badges?count=100500`);
     const typeWithSVG = data.data.filter(type => Boolean(type.image.indexOf('.svg') + 1));
    const svg = [];
    for (let t of typeWithSVG) {
      if (process.env.NODE_ENV === 'development') {
        const proxy = 'https://cors-anywhere.herokuapp.com/';
        const resp = await Axios.get(proxy + t.image)
        svg.push({
          id: t.id,
          img: resp.data,
          url: t.image
        });
      } else {
        const resp = await Axios.get(t.image);
        svg.push({
          id: t.id,
          img: resp.data,
          url: t.image
        });
      }
    }
    context.commit('SET_BADGES_SVG', svg);
    context.commit('SET_BADGES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
