import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: []
};
const getters = {
  GET_COMMENTS: state => state.data
};
const mutations = {
  SET_COMMENTS: (state, payload) => state.data = payload.data
};
const actions = {
  GET_COMMENTS_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let { data } = await Axios.get(`/api/${payload.type}/${payload.id}/comments`);
    context.commit('SET_COMMENTS', data);
  },
  GET_COMMENTS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/${payload.type}/${payload.id}/comments${string}`);
    context.commit('SET_COMMENTS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
