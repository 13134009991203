import Axios from 'axios';

const state = {
  data: null,
};
const getters = {
  GET_TRIP: state => state.data,
};
const mutations = {
  SET_TRIP: (state, payload) => state.data = payload.data,
};
const actions = {
  GET_TRIP_FROM_SERVER: async (context, payload) => {
    state.data = null;
    const { data } = await Axios.get(`/api/trips/${payload}`);
    context.commit('SET_TRIP', data);
  },
  SET_COMMENT_TO_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    const { data } = await Axios.post(`/api/trips/${payload.id}/comments${string}`);
    context.dispatch('GET_TRIP_FROM_SERVER', payload.id);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
