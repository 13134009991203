import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: null,
};

const getters = {
  GET_PLACE: state => state.data
};

const mutations = {
  SET_PLACE: (state, payload) => {
    state.data = payload.data;
  },
};

const actions = {
  GET_PLACE_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    let { data } = await Axios.get(`/api/places/${payload}`);
    context.commit('SET_PLACE', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
