import Axios from 'axios';

Axios.defaults.timeout = 180000;

const state = {
  data: [],
};

const getters = {
  GET_FACTS: state => state,
};

const mutations = {
  SET_FACTS: (state, payload) => state.data = payload.data,
  REMOVE_FIRST_FACT: (state) => {
    state.data.splice(0, 1);
  },
  ADD_LAST_FACT: (state, payload) => {
    state.data = [...state.data, payload];
  },
  ADD_FIRST_FACT: (state, payload) => {
    state.data = [payload, ...state.data];
  },
  REMOVE_LAST_FACT: (state) => {
    state.data.splice(state.data.length - 1, 1);
  },
};

const actions = {
  GET_FACTS_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/content/fact/`);
    context.commit('SET_FACTS', data);
  },

  GET_FACTS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/content/fact/${string}`);
    context.commit('SET_FACTS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
