import Axios from 'axios';

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_EVENTS: state => state,
};

const mutations = {
  UPDATE_EVENTS: (state, payload) => {
    let newArray = [];
    state.data.forEach(item => {
      if (item.id === payload.id) {
        newArray = [...newArray, payload];
      } else {
        newArray = [...newArray, item];
      }
    });
    state.data = newArray;
  },

  SET_EVENTS: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  }
};

const actions = {
  GET_EVENTS_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/content/event`);
    context.commit('SET_EVENTS', data);
  },
  GET_NEXT_EVENTS_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/content/event${string}`);
    context.commit('SET_EVENTS', data);
  },
  GET_EVENTS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/content/event${string}`);
    context.commit('SET_EVENTS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
