<template>
  <div class="news-view">
    <div class="news-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{ navigation, color: 'rgba(0,0,0,.58)' }"/>
        <component
            :is="$viewport.desktop ? 'Row' : 'Column'"
            class="news-view__title-holder"
            :wrap="$viewport.mobile"
            :align="$viewport.desktop ? 'center' : ''"
            :justify="$viewport.desktop ? 'between' : ''"
        >
          <h1 class="news-view__title">Новости и события</h1>
          <div>
            <div
                id="cityId"
                v-click-outside="closeSelect"
                class="select select-with-scroll"
                :class="show ? 'select-opened' : ''"
                v-on:click="toggleSelect"
            >
              <Icon
                  class="select-icon"
                  xlink="arrow-navigation"
                  :rotate="show ? 0 : 180"
                  viewport="0 0 20 20"
              />
              <div class="selected">{{ cities.find(item => item.id === cityId).name }}</div>
              <div v-if="show" class="select-list">
                <div
                    v-for="city in cities"
                    :key="city.id"
                    v-on:click="select(city.id)"
                    class="select-item"
                >
                  {{ city.name }}
                </div>
              </div>
            </div>
          </div>
        </component>
        <Row class="news-view__tabs">
          <router-link
              :class="{ 'news-view__tab-active': $route.path === '/news' }"
              to="/news"
              class="news-view__tab"
          >Новости
          </router-link
          >
          <router-link
              :class="{ 'news-view__tab-active': $route.path === '/events' }"
              to="/events"
              class="news-view__tab"
          >События
          </router-link
          >
        </Row>
        <Row class="news-view__filter">
          <DateTimePicker
              :time-picker="false"
              auto-close
              format="DD MMMM"
              locale="ru"
              :value="date"
              @input="setDate"
              v-on:close="changeColor"
              v-on:open="changeColor"
          >
            <template slot="choose-date">
              <div class="news-view__tag">
                Дата
                <Icon
                    :rotate="180"
                    class="news-view__tag-icon"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"
                />
              </div>
            </template>
            <template slot="formatted-datetime">
              <div class="news-view__tag news-view__tag-black">
                {{
                  isPastdate ? 'Прошедшие' : $moment(date)
                      .format('DD MMMM')
                }}
                <div @click.stop="removeDate">
                  <Icon
                      class="news-view__tag-icon news-view__tag-close"
                      viewport="0 0 20 20"
                      xlink="close"
                  />
                </div>
              </div>
            </template>
            <template slot="months-prev">
              <Icon :rotate="-90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
            <template slot="months-next">
              <Icon :rotate="90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
            <template slot="decades-prev">
              <Icon :rotate="-90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
            <template slot="decades-next">
              <Icon :rotate="90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
            <template slot="years-prev">
              <Icon :rotate="-90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
            <template slot="years-next">
              <Icon :rotate="90" viewport="0 0 20 20" xlink="arrow-navigation"/>
            </template>
          </DateTimePicker>
          <div
              class="news-view__tag"
              v-if="$viewport.desktop"
              v-on:click="today"
          >
            Сегодня
          </div>
          <div
              class="news-view__tag"
              v-if="$viewport.desktop"
              v-on:click="tomorrow"
          >
            Завтра
          </div>
          <div
              class="news-view__tag"
              v-if="$viewport.desktop"
              v-on:click="pastdate"
          >
            Прошедшие
          </div>
        </Row>
        <Row class="news-view__filter" wrap>
          <div
              v-for="type in types"
              :key="type.id"
              class="trips-view__types"
              :class="{ 'trips-view__types-active': type.id === typeId }"
              v-on:click="getEventsByType(type.id)"
          >
            {{ type.title }}
          </div>
        </Row>
        <Row class="news-view__all-news" wrap>
          <Card
              :key="item.id"
              :params="item"
              class="news-view__new"
              v-for="item in $events.data"
          />
        </Row>
        <Row v-if="$events.links.next" full justify="center">
          <Button v-on:click.native="nextPage"
                  :disabled="disabled"
                  class="news-view__more"
                  color="like-link">
            Показать еще
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <ShareOnSocialNetworks
            class="trips-view__limiter"
            :url="`${$host}/events`"
            title="Новости и события"
        />
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  data() {
    return {
      disabled: false,
      cityId: '',
      typeId: null,
      show: false,
      dates: {
        started: null,
        stopped: null,
      },
      date: '',
      isPastdate: false,
    };
  },
  created() {
    this.cityId = this.$globalCitySelected();
    this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: this.getParams(),
    });
  },
  computed: {
    cities() {
      return [
        {
          id: '',
          name: 'Все',
          slug: 'region',
        },
        ...this.$cities.data,
      ];
    },
    types() {
      return [
        {
          id: null,
          title: 'Все',
        },
        ...this.$badges,
      ];
    },
    navigation() {
      return [
        {
          href: '/',
          title: 'Главная',
        },
        {
          href: '/events',
          title: 'События',
        },
      ];
    },
  },
  methods: {
    setDate(event) {
      this.date = event;
      this.dates.started = this.$moment(event)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment(event)
        .format('YYYY-MM-DD');
      this.isPastdate = false;
      this.request();
    },
    getParams() {
      const params = {
        count: 6,
        sort: 'promo',
      };
      if (this.cityId !== '') {
        params.city_id = this.cityId;
      }
      if (this?.typeId) params.badge_id = this?.typeId;
      if (this?.dates?.started) params.started_at = this?.dates?.started;
      if (this?.dates?.stopped) params.stopped_before = this?.dates?.stopped;
      return params;
    },
    getEventsByType(typeId) {
      this.typeId = this.typeId !== typeId ? typeId : null;
      this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    select(id) {
      this.cityId = id;
      this.$store.dispatch('GET_CITY_BY_ID', {
        params: {
          city_id: id,
        },
      });
      this.request();
    },
    closeSelect() {
      this.show = false;
    },
    toggleSelect() {
      this.show = !this.show;
    },
    today() {
      this.dates.started = this.$moment()
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .format('YYYY-MM-DD');
      this.date = this.$moment();
      this.isPastdate = false;
      this.request();
    },
    tomorrow() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.date = this.$moment()
        .date(this.$moment()
          .date() + 1);
      this.isPastdate = false;
      this.request();
    },
    pastdate() {
      this.dates.started = null;
      this.dates.stopped = this.$moment()
        .date(this.$moment()
          .date() - 3)
        .format('YYYY-MM-DD');
      this.date = this.$moment()
        .date(this.$moment()
          .date() - 3);
      this.isPastdate = true;
      this.request();
    },
    removeDate() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date() - 2)
        .format('YYYY-MM-DD');
      this.dates.stopped = null;
      this.date = '';
      this.isPastdate = false;
      this.request();
    },
    request() {
      this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: this.getParams(),
      });
    },
    changeColor() {
    },
    getParameterByName(name, url) {
      const regex = new RegExp(
        // eslint-disable-next-line no-useless-escape
        `[?&]${name.replace(/[\[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`,
      );
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    nextPage() {
      this.disabled = true;
      const params = Object.assign(this.getParams(), {
        page: this.$events.links.next.split('?')[1].split('=')[1],
      });
      this.$store.dispatch('GET_NEXT_EVENTS_FROM_SERVER', {
        params,
      })
      // eslint-disable-next-line no-return-assign
        .then(() => this.disabled = false);
    },
  },
};
</script>
