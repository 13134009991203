<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"/>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettingsView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
