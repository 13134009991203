<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <PageNavigation/>
      </Section>
      <Section limiter>
        <Row class="formation-portal-margin-120"
             justify="between">
          <AccountMenu v-if="$viewport.desktop"
                       class="grid-4"/>
          <AccountContent :class="$viewport.desktop ? 'grid-8' : ''"
                          :params="{transparent: true}">
            <Row class="account-content__facts-header"
                 justify="between">
              <p class="account-content__facts-title">Мои факты</p>
              <Button v-if="$viewport.desktop"
                      color="green"
                      v-on:click.native="$openPopupAddFact">Добавить факт
              </Button>
            </Row>
            <Row justify="between"
                 wrap>
              <InterestingFact :key="fact.id"
                               :params="{fact}"
                               v-for="fact in $my.facts"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
      <Section>
        <Billboard/>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountFactsView',
  metaInfo() {
    return {
      title: 'Туристический гид по Томской области',
    };
  },
  created() {
    this.$store.dispatch('GET_MY_FACTS_FROM_SERVER');
  },
};
</script>
