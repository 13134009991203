<template>
  <div class="gastro-card-view">
    <div class="gastro-card-view__wrapper">
      <Section>
        <HeroBillboard :darkened="true" :params="{meta, src}"
                       title="Гастрономическая карта Томской области"
                       description="<p>Томская область – многонациональный регион, и это нашло свое
                        отражение в разнообразии блюд в авторском исполнении томских
                        шеф-поваров, которые мы представляем в рамках проекта
                        «Гастрономическая карта Томской области».</p>
                        <p>Кулинарные и гастрономические изыски разных народов, которые готовятся с
                        использованием местных продуктов и дикоросов, вы можете попробовать в
                        наших многочисленных кафе, ресторанах, пекарнях и кондитерских.</p>
                        <p>Выбирайте на свой вкус!</p>
                        <p>И приятного аппетита!</p>"
        />
      </Section>
      <Section limiter>
        <div class="gastro-list">
          <div class="gastro-list__map-link">
            <router-link to="/interactive-map?type_id=29">
              Перейти к интерактивной гастрономической карте
            </router-link>
          </div>
          <div class="select-container">
            <SelectComponent
              id="dishType"
              itemKey="name"
              :array="types"
              :index="type"
              v-on:change-index="selectType"
            />
          </div>
          <div
            class="gastro-region"
            v-for="(region, regionIndex) in preparedCards"
            :key="`region-${regionIndex}`"
          >
            <div class="gastro-region__title" v-if="region.items.length > 0">
              <h2>{{ region.region }}</h2>
            </div>
            <div class="gastro-region__items">
              <div
                class="gastro-card"
                v-for="(card, cardIndex) in region.items"
                :key="`place-${cardIndex}`"
              >
                <div class="gastro-card__poster" v-if="card.poster">
                  <img :src="require(`@/assets/images/gastrocard/${card.poster}`)" />
                </div>
                <div class="gastro-card__title" :class="{ '_full': !card.poster }">
                  {{ card.dish }} (<a :href="card.link" target="_blank">{{card.place}}</a>)
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GastroCardView',

  data() {
    return {
      src: 'gastrocard.jpg',
      meta: [],
      types: [
        { id: '', name: 'Тип блюда' },
        { id: 'main', name: 'Основные блюда' },
        { id: 'dessert', name: 'Десерты' },
        { id: 'drink', name: 'Напитки' },
      ],
      type: '',
      cards: [
        {
          region: 'Томск',
          items: [
            {
              dish: 'Муссовое пирожное Мокачино',
              place: 'Кондитерская «Baba Roma»',
              link: 'https://tour.tomsk.life/places/1095',
              poster: 'gc1.jpg',
              type: 'dessert',
            },
            {
              dish: 'Торт «Томск»',
              place: 'Кафе «Багет Омлет»',
              link: 'https://tour.tomsk.life/places/982',
              poster: 'gc2.jpg',
              type: 'dessert',
            },
            {
              dish: 'Томлёная ножка индейки с жжёной кукурузой и горгонзолой',
              place: 'Коктейльный бар «Барин»',
              link: 'https://tour.tomsk.life/places/1033',
              poster: 'gc3.jpg',
              type: 'main',
            },
            {
              dish: 'Кофе Латте австралийская классика',
              place: 'Кофейня «Буланже»',
              link: 'https://tour.tomsk.life/places/739',
              poster: 'gc4.jpg',
              type: 'drink',
            },
            {
              dish: 'Сибирская рыба ценных пород, особыми способами приготовленная: стерлядь горячего копчения',
              place: 'Трактир «Вечный Зов»',
              link: 'https://tour.tomsk.life/places/1233',
              poster: 'gc5.jpg',
              type: 'main',
            },
            {
              dish: 'Сибирская рыба ценных пород, особыми способами приготовленная: строганина из нельмы и муксуна',
              place: 'Трактир «Вечный Зов»',
              link: 'https://tour.tomsk.life/places/1233',
              poster: 'gc6.jpg',
              type: 'main',
            },
            {
              dish: 'Котлета из лосятины/медвежатины',
              place: 'Трактир «Вечный Зов»',
              link: 'https://tour.tomsk.life/places/1233',
              poster: 'gc7.jpg',
              type: 'main',
            },
            {
              dish: 'Бефстроганов с картофельным пюре',
              place: 'Кафе «Гости»',
              link: 'https://tour.tomsk.life/places/39',
              poster: 'gc8.jpg',
              type: 'main',
            },
            {
              dish: 'Медальоны из локальной телятины с соусом из белых грибов и картофельными драниками',
              place: 'Клуб «Доберман»',
              link: 'https://tour.tomsk.life/places/1230',
              poster: 'gc9.jpg',
              type: 'main',
            },
            {
              dish: 'Вафли с бананом и соленой карамелью',
              place: 'Кафе «Желток»',
              link: 'https://tour.tomsk.life/places/1178',
              poster: 'gc10.jpg',
              type: 'main',
            },
            {
              dish: 'Римская пицца «Марчелло»',
              place: 'Ресторан «Иtaly»',
              link: 'https://tour.tomsk.life/places/990',
              poster: 'gc11.jpg',
              type: 'main',
            },
            {
              dish: 'Кедрокофе',
              place: 'Экокафе «Кедрокофе» Сибереко',
              link: 'https://tour.tomsk.life/places/1231',
              poster: 'gc12.jpg',
              type: 'drink',
            },
            {
              dish: 'Торт *Томский от Klaus Café* - торт по особому рецепту, разработанному в Klaus Café в 2015 году',
              place: 'Кафе-кондитерская «Клаус»',
              link: 'https://tour.tomsk.life/places/1210',
              poster: 'gc13.jpg',
              type: 'dessert',
            },
            {
              dish: 'Голень с еловой шишкой, гречневой кашей и белыми грибами',
              place: 'Ирландский паб «Clever»',
              link: 'https://tour.tomsk.life/places/1026',
              poster: 'gc14.jpg',
              type: 'main',
            },
            {
              dish: 'Стерлядь по-купечески',
              place: 'Ресторан «Кухтерин»',
              link: 'https://tour.tomsk.life/places/1005',
              poster: 'gc15.jpg',
              type: 'main',
            },
            {
              dish: 'Кедровый Латте',
              place: 'Кафе «Leto»',
              link: 'https://tour.tomsk.life/places/1008',
              poster: 'gc16.jpg',
              type: 'drink',
            },
            {
              dish: 'Люля-кебаб из щуки и судака - гриль-блюдо соусом песто и лимоном',
              place: 'Гастро-кафе «Soft Loft»',
              link: 'https://tour.tomsk.life/places/1219',
              poster: 'gc17.jpg',
              type: 'main',
            },
            {
              dish: 'Тако с брискетом, луково-можжевеловым мармеладом и кедровым орехом',
              place: 'Латино-американский гастробар «El Mucho»',
              link: 'https://tour.tomsk.life/places/1232',
              poster: 'gc18.jpg',
              type: 'main',
            },
            {
              dish: 'Рубленная оленина с картофелем на углях',
              place: 'Ресторан «Нарым»',
              link: 'https://tour.tomsk.life/places/1092',
              poster: 'gc19.jpg',
              type: 'main',
            },
            {
              dish: 'Гратен с пармезаном и трюфельным соусом',
              place: 'Гастро-бистро «Nova»',
              link: 'https://tour.tomsk.life/places/1177',
              poster: 'gc20.jpg',
              type: 'main',
            },
            {
              dish: 'Пицца из дровяной печи из локальных сибирских продуктов, с мраморной алтайской телятиной и маринованными огурчиками',
              place: 'Гастро-бар «К папе на PIZZA»',
              link: 'https://tour.tomsk.life/places/1102',
              poster: 'gc81.jpg',
              type: 'main',
            },
            {
              dish: 'Сковорода от Папы',
              place: 'Руки ВВерх! Бар',
              link: 'https://tour.tomsk.life/places/1430',
              poster: 'gc82.jpg',
              type: 'main',
            },
            {
              dish: 'Торт Наполеон с жимолостью',
              place: 'Кафе «Pastamama»',
              link: 'https://tour.tomsk.life/places/1009',
              poster: 'gc21.jpg',
              type: 'dessert',
            },
            {
              dish: 'Традиционный пирог с жюльеном из курицы с грибами',
              place: 'Кафе-пекарня «Пирога»',
              link: 'https://tour.tomsk.life/places/1220',
              poster: 'gc22.jpg',
              type: 'main',
            },
            {
              dish: 'Молочный коктейль',
              place: 'Кафе «ПиццеРио»',
              link: 'https://tour.tomsk.life/places/1221',
              poster: 'gc23.jpg',
              type: 'drink',
            },
            {
              dish: 'Сырники с апельсиновой цедрой и облепиховой сметаной',
              place: 'Кофейня «ProCofe»',
              link: 'https://tour.tomsk.life/places/998',
              poster: 'gc24.jpg',
              type: 'main',
            },
            {
              dish: 'Пельмени с щучьей икрой',
              place: 'Ресторан «Пряности & Радости»',
              link: 'https://tour.tomsk.life/places/465',
              poster: 'gc25.jpg',
              type: 'main',
            },
            {
              dish: 'Медальоны из марала и гребешка',
              place: 'Ресторан «Река 827»',
              link: 'https://tour.tomsk.life/places/31',
              poster: 'gc26.jpg',
              type: 'main',
            },
            {
              dish: 'Пиво «Йохан Безалкогольное»',
              place: 'Ресторан-пивоварня «Йохан Пивохан»',
              link: 'https://tour.tomsk.life/places/1030',
              poster: 'gc27.jpg',
              type: 'drink',
            },
            {
              dish: 'Говядина «Беф Бургиньон» и лимонад из жимолости',
              place: 'Стерео-кафе «Santa Monica»',
              link: 'https://tour.tomsk.life/places/810',
              poster: 'gc28.jpg',
              type: 'main',
            },
            {
              dish: 'Десерт «Черёмуха-жимолость»',
              place: 'Кондитерская студия «Sweet Concept»',
              link: 'https://tour.tomsk.life/places/1229',
              poster: 'gc29.jpg',
              type: 'dessert',
            },
            {
              dish: 'Щучья котлета с соусом из жимолости',
              place: 'Трактир «Сибирская трапеза»',
              link: 'https://tour.tomsk.life/places/814',
              poster: 'gc30.jpg',
              type: 'main',
            },
            {
              dish: 'Блин с красной икрой по-царски',
              place: 'Сеть кафе-блинных «Сибирские блины»',
              link: 'https://tour.tomsk.life/places/1020',
              poster: 'gc31.jpg',
              type: 'main',
            },
            {
              dish: 'Чебуречки с мясом',
              place: 'Кафе «Сибирское бистро»',
              link: 'https://tour.tomsk.life/places/1222',
              poster: 'gc32.jpg',
              type: 'main',
            },
            {
              dish: 'Котлетки из карасей по-деревенски',
              place: 'Ресторан «Сибирь»',
              link: 'https://tour.tomsk.life/places/1006',
              poster: 'gc33.jpg',
              type: 'main',
            },
            {
              dish: 'Пельмени «Томские»',
              place: 'Кафе-пельменная «Скалка»',
              link: 'https://tour.tomsk.life/places/1223',
              poster: 'gc34.jpg',
              type: 'main',
            },
            {
              dish: 'Вареники «Жимолость»',
              place: 'Кафе-пельменная «Скалка»',
              link: 'https://tour.tomsk.life/places/1223',
              poster: 'gc35.jpg',
              type: 'main',
            },
            {
              dish: 'Язычки ягнёнка с Томской сливой и чечевицей',
              place: 'Бар-ресторан «Снегири»',
              link: 'https://tour.tomsk.life/places/986',
              poster: 'gc36.jpg',
              type: 'main',
            },
            {
              dish: 'Обской муксун с овощами, в соусе из лимона',
              place: 'Бар-ресторан «Снегири»',
              link: 'https://tour.tomsk.life/places/986',
              type: 'main',
            },
            {
              dish: 'Говяжье ребро с брусничным соусом',
              place: 'Бар-ресторан «Снегири»',
              link: 'https://tour.tomsk.life/places/986',
              poster: 'gc38.jpg',
              type: 'main',
            },
            {
              dish: 'Чебурята',
              place: 'Кафе-музей «Солянка»',
              link: 'https://tour.tomsk.life/places/817',
              poster: 'gc39.jpg',
              type: 'main',
            },
            {
              dish: 'Драник с молодой телятиной на луковой подушке со шкварками',
              place: 'Ресторан «Союз»',
              link: 'https://tour.tomsk.life/places/1224',
              poster: 'gc40.jpg',
              type: 'main',
            },
            {
              dish: 'Сырные вафли с лососем и соусом голандез',
              place: 'Кафе «СчастьЕсть»',
              link: 'https://tour.tomsk.life/places/800',
              poster: 'gc41.jpg',
              type: 'main',
            },
            {
              dish: 'Сырная тарелка',
              place: 'Гастробутик «Сырная коллекция»',
              link: 'https://tour.tomsk.life/places/1225',
              poster: 'gc42.jpg',
              type: 'main',
            },
            {
              dish: 'Фокачча с бурратой и томатами',
              place: 'Ресторан «Сыроварня»',
              link: 'https://tour.tomsk.life/places/991',
              poster: 'gc43.jpg',
              type: 'main',
            },
            {
              dish: 'Торт "Степка в клюкве"',
              place: 'Кондитерская «TORTA»',
              link: 'https://tour.tomsk.life/places/911',
              poster: 'gc44.jpg',
              type: 'dessert',
            },
            {
              dish: '«Любимое кушанье Карла Крюгера»',
              place: 'Ресторанный комплекс Дом пива «У Крюгера»',
              link: 'https://tour.tomsk.life/places/1226',
              poster: 'gc45.jpg',
              type: 'main',
            },
            {
              dish: 'Рулька запеченная',
              place: 'Ресторанный комплекс Дом пива «У Крюгера»',
              link: 'https://tour.tomsk.life/places/1226',
              poster: 'gc46.jpg',
              type: 'main',
            },
            {
              dish: 'Стейк Харди Гарди',
              place: 'Гриль-бар «Харди Гарди»',
              link: 'https://tour.tomsk.life/places/1227',
              poster: 'gc47.jpg',
              type: 'main',
            },
            {
              dish: 'Хачапури по-аджарски',
              place: 'Ресторан грузинской кухни «Южане»',
              link: 'https://tour.tomsk.life/places/1010',
              poster: 'gc48.jpg',
              type: 'main',
            },
            {
              dish: 'Хинкали с бараниной',
              place: 'Кафе «Хинкали Хо»',
              link: 'https://tour.tomsk.life/places/1228',
              poster: 'gc49.jpg',
              type: 'main',
            },
            {
              dish: 'Хинкали с говядиной и свининой',
              place: 'Кафе «Хинкали Хо»',
              link: 'https://tour.tomsk.life/places/1010',
              poster: 'gc50.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Александровский район',
          items: [
            {
              dish: 'Бургер Potato Patty',
              place: 'Кафе «Точка»',
              link: 'https://tour.tomsk.life/places/1234',
              poster: 'gc51.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Асиновский район',
          items: [
            {
              dish: 'Сибирский ноктюрн',
              place: 'Кафе «Элит»',
              link: 'https://tour.tomsk.life/places/1235',
              poster: 'gc52.jpg',
              type: 'main',
            },
            {
              dish: 'Van Classen Set',
              place: 'Сырная лавка «Van Classen»',
              link: 'https://tour.tomsk.life/places/853',
              poster: 'gc53.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Бакчарский район',
          items: [
            {
              dish: 'Пирожки с начинкой из жимолости',
              place: 'Магазин-закусочная «Хлеб»',
              link: 'https://tour.tomsk.life/places/1236',
              poster: 'gc54.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Верхнекетский район',
          items: [
            {
              dish: 'Котлета "Сибирская"',
              place: 'Кафе «Домашняя кухня»',
              link: 'https://tour.tomsk.life/places/1237',
              poster: 'gc55.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Зырянский район',
          items: [
            {
              dish: 'Запёченный картофель с котлетой фаршированной',
              place: 'Кафе «Берёзовая роща»',
              link: 'https://tour.tomsk.life/places/1238',
              poster: 'gc56.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Каргасокский район',
          items: [
            {
              dish: 'Хе из судака',
              place: 'Кафетерий',
              link: 'https://tour.tomsk.life/places/1243',
              type: 'main',
            },
            {
              dish: 'Щука фаршированная с кедровыми орешками',
              place: 'Кафетерий',
              link: 'https://tour.tomsk.life/places/1243',
              poster: 'gc58.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Кожевниковский район',
          items: [
            {
              dish: 'Манты с разной начинкой',
              place: 'Кафе «Вега»',
              link: 'https://tour.tomsk.life/places/1239',
              poster: 'gc59.jpg',
              type: 'main',
            },
            {
              dish: 'Лагман',
              place: 'Кафе «Милена»',
              link: 'https://tour.tomsk.life/places/1240',
              type: 'main',
            },
            {
              dish: 'Пирог «кухен с калиной и сметаной»',
              place: 'Кафе «Минутка»',
              link: 'https://tour.tomsk.life/places/1242',
              type: 'main',
            },
            {
              dish: 'Сборная мясная солянка',
              place: 'Кафе «Родничок»',
              link: 'https://tour.tomsk.life/places/1241',
              poster: 'gc62.jpg',
              type: 'main',
            },
            {
              dish: 'Толма',
              place: 'Кафе «У Гарика»',
              link: 'https://tour.tomsk.life/places/840',
              poster: 'gc63.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Колпашевский район',
          items: [
            {
              dish: 'Шаурма «Фирменная»',
              place: 'Кафе «Мангал»',
              link: 'https://tour.tomsk.life/places/1244',
              poster: 'gc64.jpg',
              type: 'main',
            },
            {
              dish: 'Салат «Гнездо глухаря»',
              place: 'Кафе «Легенда»',
              link: 'https://tour.tomsk.life/places/1245',
              poster: 'gc65.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Кривошеинский район',
          items: [
            {
              dish: 'Домашняя выпечка',
              place: 'Кафе «Уют»',
              link: 'https://tour.tomsk.life/places/1246',
              poster: 'gc66.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Молчановский район',
          items: [
            {
              dish: 'Стейк из свинины',
              place: 'Кафе «Сафари»',
              link: 'https://tour.tomsk.life/places/1247',
              poster: 'gc67.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Парабельский район',
          items: [
            {
              dish: 'Лосятина «Таёжная» в горшочке',
              place: 'Ресторан «Левкада»',
              link: 'https://tour.tomsk.life/places/1248',
              poster: 'gc68.jpg',
              type: 'main',
            },
            {
              dish: 'Пельмени «Охотничьи» с грибным бульоном',
              place: 'Ресторан «Левкада»',
              link: 'https://tour.tomsk.life/places/1248',
              type: 'main',
            },
            {
              dish: 'Уха «по-Парабельски»',
              place: 'Ресторан «Левкада»',
              link: 'https://tour.tomsk.life/places/1248',
              type: 'main',
            },
          ],
        },
        {
          region: 'Первомайский район',
          items: [
            {
              dish: 'Первомайские пышки',
              place: 'ТрактирЪ «Жили-были»',
              link: 'https://tour.tomsk.life/places/1249',
              poster: 'gc71.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Северск',
          items: [
            {
              dish: 'Филе утиной грудки с грушей и брусничным соусом',
              place: 'Ресторан «Венеция»',
              link: 'https://tour.tomsk.life/places/1255',
              poster: 'gc72.jpg',
              type: 'main',
            },
            {
              dish: 'Медальон из говяжьей вырезки с вишневым соусом',
              place: 'Ресторан «Венеция»',
              link: 'https://tour.tomsk.life/places/1255',
              poster: 'gc73.jpg',
              type: 'main',
            },
            {
              dish: 'Сом запеченный',
              place: 'Кафе «Оливье»',
              link: 'https://tour.tomsk.life/places/1256',
              poster: 'gc74.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Стрежевой',
          items: [
            {
              dish: 'Салат "Томская волость"',
              place: 'Тики-бар «Тропикал»',
              link: 'https://tour.tomsk.life/places/1257',
              poster: 'gc75.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Тегульдетский район',
          items: [
            {
              dish: 'Черничные капкейки',
              place: 'Кафе «Лора»',
              link: 'https://tour.tomsk.life/places/1250',
              poster: 'gc76.jpg',
              type: 'dessert',
            },
          ],
        },
        {
          region: 'Томский район',
          items: [
            {
              dish: 'Суп с домашней лапшой',
              place: 'Кафе «Чишмя»',
              link: 'https://tour.tomsk.life/places/1251',
              poster: 'gc77.jpg',
              type: 'main',
            },
            {
              dish: 'Люля–кебаб в перце',
              place: 'Кафе «100 видов шашлыков»',
              link: 'https://tour.tomsk.life/places/1252',
              poster: 'gc78.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Чаинский район',
          items: [
            {
              dish: 'Свиная отбивная с печеным картофелем',
              place: 'Кафе «Рябинка»',
              link: 'https://tour.tomsk.life/places/1253',
              poster: 'gc79.jpg',
              type: 'main',
            },
          ],
        },
        {
          region: 'Шегарский район',
          items: [
            {
              dish: 'Фирменный салат с куриной печенью',
              place: 'Семейное кафе «Familia»',
              link: 'https://tour.tomsk.life/places/1254',
              poster: 'gc80.jpg',
              type: 'main',
            },
          ],
        },
      ],
    };
  },

  computed: {
    preparedCards() {
      if (this.type) {
        const cards = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const region of this.cards) {
          const items = [...region.items].filter(item => item.type === this.type);
          cards.push({ region: region.region, items });
        }

        return cards;
      }

      return this.cards;
    },
  },

  methods: {
    selectType(type) {
      this.type = type;
    },
  },
};
</script>

<style lang="scss" scoped>
 .gastro-list {
   padding-bottom: 1rem;

   &__map-link {
     margin-bottom: 1rem;

     a {
       font-weight: bold;
       font-size: 22px;
       color: #00a367;

       &:hover {
         text-decoration: underline;
       }
     }
   }

   .gastro-region {
     &__title {
       padding-top: 1rem;
       padding-bottom: 2rem;

       h2 {
         font-size: 32px;
         text-align: center;
       }
     }
   }

   .gastro-card {
     margin-bottom: 2rem;
     display: flex;
     justify-content: center;

     &__title {
       font-size: 18px;
       font-weight: bold;
       margin-bottom: 1rem;
       width: 36%;

       &._full {
         width: 100%;
         text-align: center;
       }

       a {
         color: #00a367;

         &:hover {
           text-decoration: underline;
         }
       }
     }

     &__poster {
       padding-right: 1.5rem;
       width: 50%;

       img {
         max-width: 100%;
         float: right;
       }
     }
   }
 }

 @media (max-width: 1110px) {
   .gastro-list {
     .gastro-card {
       flex-direction: column-reverse;

       &__title {
         width: 100%;
         line-height: 150%;
         text-align: left !important;
       }

       &__poster {
         width: 100%;
       }
     }
   }
 }
</style>
