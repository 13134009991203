import moment from 'moment';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import YandexMap from 'vue-yandex-maps';
import SocialSharing from 'vue-social-sharing';
import Vue2TouchEvents from 'vue2-touch-events';
import linkify from 'vue-linkify';

import Vue from 'vue';
import Axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import methods from './methods';
import computed from './computed';
import clickOutside from './directive/click-outside';

Axios.defaults.timeout = 180000;
// Axios.defaults.baseURL = 'http://localhost';

Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale('ru');
Vue.prototype.$http = Axios;

const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

Vue.use(YandexMap, settings);
Vue.use(Vue2TouchEvents);
Vue.use(SocialSharing);

Vue.component('AccountContent', () => import('./components/Account/AccountContent/index'));
Vue.component('AccountDashboard', () => import('./components/Account/AccountDashboard/index'));
Vue.component('AccountMenu', () => import('./components/Account/AccountMenu'));
Vue.component('AddFile', () => import('./components/AddFile'));
Vue.component('AgencyCardEditor', () => import('./components/AgencyCard/AgencyCardEditor'));
Vue.component('Author', () => import('./components/Author'));
Vue.component('Billboard', () => import('./components/Billboard'));
Vue.component('BreadCrumbs', () => import('./components/UI/BreadCrumbs/index'));
Vue.component('Button', () => import('./components/Button'));
Vue.component('Card', () => import('./components/UI/Cards/Card'));
Vue.component('ChooseMunicipality', () => import('./components/ChooseMunicipality'));
Vue.component('Column', () => import('./components/Column'));
Vue.component('Contents', () => import('./components/Contents/index'));
Vue.component('DateTimePicker', DateTimePicker);
Vue.component('Divider', () => import('./components/Divider'));
Vue.component('ExcursionCard', () => import('./components/UI/ExcursionCard/index'));
Vue.component('ExcursionCardEditor', () => import('./components/ExcursionCard/ExcursionCardEditor'));
Vue.component('Favorite', () => import('./components/Favorite'));
Vue.component('Feedback', () => import('./components/Feedback'));
Vue.component('DocumentsSection', () => import('./components/DocumentsSection'));
Vue.component('Footer', () => import('./components/Footer'));
Vue.component('FormationPortal', () => import('./components/FormationPortal'));
Vue.component('Gallery', () => import('./components/Gallery'));
Vue.component('GuestReviews', () => import('./components/GuestReviews/index'));
Vue.component('GuideCard', () => import('./components/GuideCard/index'));
Vue.component('YandexComments', () => import('./components/YandexComments'));
Vue.component('GuideCardEditor', () => import('./components/GuideCard/GuideCardEditor'));
Vue.component('Header', () => import('./components/Header/index'));
Vue.component('HeroBillboard', () => import('./components/HeroBillboard'));
Vue.component('Icon', () => import('./components/Icon'));
Vue.component('IconSprite', () => import('./components/IconSprite'));
Vue.component('Info', () => import('./components/Info'));
Vue.component('InputComponent', () => import('./components/InputComponent'));
Vue.component('InterestingFact', () => import('./components/InterestingFacts/InterestingFact'));
Vue.component('Loader', () => import('./components/Loader'));
Vue.component('NewGuideCard', () => import('./components/GuideCard/NewGuideCard'));
Vue.component('OnMap', () => import('./components/UI/OnMap/index'));
Vue.component('PageNavigation', () => import('./components/PageNavigation'));
Vue.component('Place', () => import('./components/Places/Place'));
Vue.component('Plot', () => import('./components/Plots/Plot'));
Vue.component('PopularRoute', () => import('./components/PopularRoutes/PopularRoute'));
Vue.component('FlashmobItem', () => import('./components/FlashmobItem'));
Vue.component('PopularRouteEdit', () => import('./components/PopularRoutes/PopularRouteEdit'));
Vue.component('Popup', () => import('./components/Popup/index'));
Vue.component('PopupAddAgencies', () => import('./components/Popup/PopupAddAgencies'));
Vue.component('PopupAddComment', () => import('./components/Popup/PopupAddComment'));
Vue.component('PopupAddExcursion', () => import('./components/Popup/PopupAddExcursion'));
Vue.component('PopupAddFact', () => import('./components/Popup/PopupAddFact'));
Vue.component('PopupAddGuide', () => import('./components/Popup/PopupAddGuide'));
Vue.component('PopupAddObject', () => import('./components/Popup/PopupAddObject'));
Vue.component('PopupAddSomething', () => import('./components/Popup/PopupAddSomething'));
Vue.component('PopupAddTrip', () => import('./components/Popup/PopupAddTrip'));
Vue.component('PopupAudioGuide', () => import('./components/Popup/PopupAudioGuide'));
Vue.component('PopupAudioTrips', () => import('./components/Popup/PopupAudioTrips'));
Vue.component('PopupCommentOnModeration', () => import('./components/Popup/PopupCommentOnModeration'));
Vue.component('PopupGuide', () => import('./components/Popup/PopupGuide'));
Vue.component('PopupSuccessfulAddExcursion', () => import('./components/Popup/PopupSuccessfulAddExcursion'));
Vue.component('PopupFlashmob', () => import('./components/Popup/PopupFlashmob'));
Vue.component('PopupFlashmobItem', () => import('./components/Popup/PopupFlashmobItem'));
Vue.component('PopupSuccessfulAddFact', () => import('./components/Popup/PopupSuccessfulAddFact'));
Vue.component('PopupSuccessfulAddObject', () => import('./components/Popup/PopupSuccessfulAddObject'));
Vue.component('PopupSuccessfulAddReview', () => import('./components/Popup/PopupSuccessfulAddReview'));
Vue.component('PopupTermsConditions', () => import('./components/Popup/PopupTermsConditions'));
Vue.component('Rating', () => import('./components/Rating'));
Vue.component('ResponseForSearch', () => import('./components/ResponseForSearch'));
Vue.component('ReviewTourists', () => import('./components/ReviewsTourists/ReviewTourists'));
Vue.component('Row', () => import('./components/Row'));
Vue.component('Section', () => import('./components/Section'));
Vue.component('SelectComponent', () => import('./components/SelectComponent'));
Vue.component('ShareOnSocialNetworks', () => import('./components/ShareOnSocialNetworks'));
Vue.component('Slider', () => import('./components/Slider'));
Vue.component('SocialBoard', () => import('./components/SocialBoard'));
Vue.component('Tag', () => import('./components/Tag/index'));
Vue.component('Tags', () => import('./components/Tags/index'));
Vue.component('TourCompanyMenu', () => import('./components/TourCompanyMenu'));
Vue.component('TripHeroImage', () => import('./components/TripHeroImage/index'));
Vue.component('TripHeroImages', () => import('./components/TripHeroImages/index'));
Vue.component('UserAvatar', () => import('./components/UserAvatar'));
Vue.component('VideoYouTube', () => import('./components/VideoYouTube'));
Vue.component('VideoRuTube', () => import('./components/VideoRuTube'));
Vue.component('YandexMap', YandexMap);

Vue.filter('countCharsInString', (string, count) => {
  if (!string) return '';
  if (string.length > count) {
    return `${string.slice(0, count)}...`;
  }
  return string;
});

Vue.directive('click-outside', clickOutside);
Vue.directive('linkified', linkify);

Vue.mixin({
  methods,
  computed,
});

Vue.config.productionTip = false;

// на сколько такое легально?
router.beforeEach(async (to, from, next) => {
  const splitPath = to.path.split('/');
  if (splitPath[1] === 'content') {
    store.commit('SET_TYPE_PAGE', splitPath[2]);
  } else {
    store.commit('SET_TYPE_PAGE', splitPath[1]);
  }
  next();
});

window.vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
